/* ###### Timeline  ###### */

.vtimeline {
    list-style: none;
    padding: 0;
    position: relative;
    margin-bottom: 20px;
  
    &:before {
      top: 0;
      bottom: 0;
      position: absolute;
      content: " ";
      width: 3px;
      background-color: #d1d4e4;
      left: 50%;
      margin-left: -1.5px;
    }
  
    .timeline-wrapper {
      display: block;
      margin-bottom: 20px;
      position: relative;
      width: 100%;
      padding-right: 90px;
  
      &:before {
        content: " ";
        display: table;
      }
  
      &:after {
        content: " ";
        display: table;
        clear: both;
      }
  
      .timeline-panel {
        border-radius: 2px;
        padding: 20px;
        position: relative;
        background: #e3e8ef;
        border-radius: 0px;
        width: 35%;
        margin-left: 15%;
  
        &:before {
          position: absolute;
          top: 0;
          width: 100%;
          height: 2px;
          content: "";
          left: 0;
          right: 0;
        }
  
        &:after {
          position: absolute;
          top: 10px;
          right: -14px;
          display: inline-block;
          border-top: 14px solid transparent;
          border-left: 14px solid #e3e8ef;
          border-right: 0 solid #e3e8ef;
          border-bottom: 14px solid transparent;
          content: " ";
        }
  
        .timeline-title {
          font-size: 1rem;
          font-weight: 700;
          margin: 0 0 .625rem;
        }
  
        .timeline-body {
          p + p {
            margin-top: 5px;
          }
  
          ul {
            margin-bottom: 0;
          }
        }
  
        .timeline-footer span {
          font-size: .6875rem;
        }
      }
  
      .timeline-badge {
        width: 14px;
        height: 14px;
        position: absolute;
        top: 16px;
        left: calc(50% - 7px);
        z-index: 0;
        border-top-right-radius: 50%;
        border-top-left-radius: 50%;
        border-bottom-right-radius: 50%;
        border-bottom-left-radius: 50%;
        border: 2px solid $white;
  
        i {
          color: $white;
        }
      }
  
      &.timeline-inverted {
        padding-right: 0;
        padding-left: 90px;
  
        .timeline-panel {
          margin-left: auto;
          margin-right: 15%;
  
          &:after {
            border-left-width: 0;
            border-right-width: 14px;
            left: -14px;
            right: auto;
          }
        }
      }
    }
  }
  
  .timeline-wrapper-primary {
    .timeline-panel:before, .timeline-badge {
      background: $primary;
    }
  }
  
  .timeline-wrapper-secondary {
    .timeline-panel:before, .timeline-badge {
      background: #eb6f33;
    }
  }
  
  .timeline-wrapper-success {
    .timeline-panel:before, .timeline-badge {
      background: #03c895;
    }
  }
  
  .timeline-wrapper-info {
    .timeline-panel:before, .timeline-badge {
      background: #01b8ff;
    }
  }
  
  .timeline-wrapper-warning {
    .timeline-panel:before, .timeline-badge {
      background: #fcd539;
    }
  }
  
  .timeline-wrapper-danger {
    .timeline-panel:before, .timeline-badge {
      background: #ff473d;
    }
  }
  
  .timeline-wrapper-light {
    .timeline-panel:before, .timeline-badge {
      background: #eff2f7;
    }
  }
  
  .timeline-wrapper-dark {
    .timeline-panel:before, .timeline-badge {
      background: $gray-900;
    }
  }
  
  @media (max-width: 767px) {
    .timeline .btn {
      font-size: 12px;
    }
  
    .vtimeline {
      &:before {
        left: 6px !important;
      }
  
      .timeline-wrapper {
        .timeline-badge {
          left: 0 !important;
        }
  
        padding-right: 0 !important;
  
        .timeline-panel {
          width: auto !important;
          margin-left: 10% !important;
  
          &:after {
            border-right: 14px solid #e3e8ef !important;
            border-left: 0 solid #e3e8ef !important;
            left: -14px !important;
            right: auto !important;
          }
        }
  
        &.timeline-inverted {
          padding-left: 0 !important;
  
          .timeline-panel {
            margin-right: 0 !important;
            width: auto !important;
          }
        }
      }
    }
  }
  
  /* ###### Timeline  ###### */