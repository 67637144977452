/* ###### Image ###### */

.main-img-user {
    display: inline-block;
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    text-align: center;
  
    img {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover;
      border-radius: 100%;
    }
  }
  
  .main-profile-menu .profile-user img {
    width: 37px;
    height: 37px;
    border-radius: 50%;
  }
  
  .main-img-user {
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 5px;
      width: 6px;
      height: 6px;
      background-color: $gray-500;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
      border-radius: 100%;
      bottom: 2px;
    }
  
    &.online::after {
      background-color: $success;
    }
  }
  
  .main-avatar {
    position: relative;
    width: 36px;
    height: 36px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-weight: 600;
    font-size: 16px;
    background-color: $gray-700;
  
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: 3px;
      width: 6px;
      height: 6px;
      background-color: $gray-500;
      box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.95);
      border-radius: 100%;
    }
  
    &.online::after {
      background-color: $success;
    }
  }
  
  .avatar-xs {
    width: 24px !important;
    height: 24px !important;
    font-size: 11px !important;
  
    &::after {
      width: 5px;
      height: 5px;
    }
  }
  
  .avatar-sm {
    width: 32px !important;
    height: 32px !important;
    font-size: 14px !important;
  
    &::after {
      width: 7px;
      height: 7px;
    }
  }
  
  .avatar-md {
    width: 48px !important;
    height: 48px !important;
    font-size: 24px !important;
  
    &::after {
      width: 9px;
      height: 9px;
      right: 2px;
      bottom: 2px;
    }
  }
  
  .avatar-lg {
    width: 64px !important;
    height: 64px !important;
    font-size: 28px !important;
  
    &::after {
      width: 10px;
      height: 10px;
      bottom: 3px;
      right: 4px;
    }
  }
  
  .avatar-xl {
    width: 72px !important;
    height: 72px !important;
    font-size: 36px !important;
  
    &::after {
      width: 11px;
      height: 11px;
      bottom: 4px;
      right: 5px;
      box-shadow: 0 0 0 2.5px $white;
    }
  }
  
  .avatar-xxl {
    width: 100px !important;
    height: 100px !important;
    font-size: 56px !important;
  
    &::after {
      width: 13px;
      height: 13px;
      bottom: 6px;
      right: 7px;
      box-shadow: 0 0 0 3px $white;
    }
  }
  
  /* ###### Image ###### */