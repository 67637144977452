/* ###### Navigation   ###### */

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  }
  
  .nav-link {
    display: block;
    padding: 0.5rem 1rem;
  
    &:hover, &:focus {
      text-decoration: none;
    }
  
    &.disabled {
      color: $gray-600;
      pointer-events: none;
      cursor: default;
    }
  }
  
  .nav-tabs {
    border-bottom: 1px solid $gray-300;
  
    .nav-item {
      margin-bottom: -1px;
    }
  
    .nav-link {
      border: 1px solid transparent;
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
  
      &:hover, &:focus {
        border-color: $gray-200 $gray-200 $gray-300;
      }
  
      &.disabled {
        color: $gray-600;
        background-color: transparent;
        border-color: transparent;
      }
  
      &.active {
        color: $gray-700;
        background-color: $white;
        border-color: $gray-300  $gray-300  $white;
      }
    }
  
    .nav-item.show .nav-link {
      color: $gray-700;
      background-color: $white;
      border-color: $gray-300  $gray-300  $white;
    }
  
    .dropdown-menu {
      margin-top: -1px;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }
  }
  
  .nav-pills {
    .nav-link {
      border-radius: 3px;
  
      &.active {
        color: $white;
        background-color: $primary;
      }
    }
  
    .show > .nav-link {
      color: $white;
      background-color: $primary;
    }
  }
  
  .nav-fill .nav-item {
    flex: 1 1 auto;
    text-align: center;
  }
  
  .nav-justified .nav-item {
    flex-basis: 0;
    flex-grow: 1;
    text-align: center;
  }
  
  .tab-content > {
    .tab-pane {
      display: none;
    }
  
    .active {
      display: block;
    }
  }
  
  .navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
  
    > {
      .container, .container-fluid {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  
  .navbar-brand {
    display: inline-block;
    padding-top: 0.33594rem;
    padding-bottom: 0.33594rem;
    margin-right: 1rem;
    font-size: 1.09375rem;
    line-height: inherit;
    white-space: nowrap;
  
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  
  .navbar-nav {
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
  
    .nav-link {
      padding-right: 0;
      padding-left: 0;
    }
  
    .dropdown-menu {
      position: static;
      float: none;
    }
  }
  
  .navbar-text {
    display: inline-block;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .navbar-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.09375rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 3px;
  
    &:hover, &:focus {
      text-decoration: none;
    }
  }
  
  .navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: "";
    background: no-repeat center center;
    background-size: 100% 100%;
  }
  
  @media (max-width: 575.98px) {
    .navbar-expand-sm > {
      .container, .container-fluid {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  
  @media (min-width: 576px) {
    .navbar-expand-sm {
      flex-flow: row nowrap;
      justify-content: flex-start;
  
      .navbar-nav {
        flex-direction: row;
  
        .dropdown-menu {
          position: absolute;
        }
  
        .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
  
      > {
        .container, .container-fluid {
          flex-wrap: nowrap;
        }
      }
  
      .main-header {
        display: flex !important;
        flex-basis: auto;
      }
  
      .navbar-toggler {
        display: none;
      }
    }
  }
  
  @media (max-width: 767.98px) {
    .navbar-expand-md > {
      .container, .container-fluid {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  
  @media (min-width: 768px) {
    .navbar-expand-md {
      flex-flow: row nowrap;
      justify-content: flex-start;
  
      .navbar-nav {
        flex-direction: row;
  
        .dropdown-menu {
          position: absolute;
        }
  
        .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
  
      > {
        .container, .container-fluid {
          flex-wrap: nowrap;
        }
      }
  
      .main-header {
        display: flex !important;
        flex-basis: auto;
      }
  
      .navbar-toggler {
        display: none;
      }
    }
  }
  
  @media (max-width: 991.98px) {
    .navbar-expand-lg > {
      .container, .container-fluid {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  
  @media (min-width: 992px) {
    .navbar-expand-lg {
      flex-flow: row nowrap;
      justify-content: flex-start;
  
      .navbar-nav {
        flex-direction: row;
  
        .dropdown-menu {
          position: absolute;
        }
  
        .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
  
      > {
        .container, .container-fluid {
          flex-wrap: nowrap;
        }
      }
  
      .main-header {
        display: flex !important;
        flex-basis: auto;
      }
  
      .navbar-toggler {
        display: none;
      }
    }
  }
  
  @media (max-width: 1199.98px) {
    .navbar-expand-xl > {
      .container, .container-fluid {
        padding-right: 0;
        padding-left: 0;
      }
    }
  }
  
  @media (min-width: 1200px) {
    .navbar-expand-xl {
      flex-flow: row nowrap;
      justify-content: flex-start;
  
      .navbar-nav {
        flex-direction: row;
  
        .dropdown-menu {
          position: absolute;
        }
  
        .nav-link {
          padding-right: 0.5rem;
          padding-left: 0.5rem;
        }
      }
  
      > {
        .container, .container-fluid {
          flex-wrap: nowrap;
        }
      }
  
      .main-header {
        display: flex !important;
        flex-basis: auto;
      }
  
      .navbar-toggler {
        display: none;
      }
    }
  }
  
  .navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
  
    > {
      .container, .container-fluid {
        padding-right: 0;
        padding-left: 0;
      }
    }
  
    .navbar-nav {
      flex-direction: row;
  
      .dropdown-menu {
        position: absolute;
      }
  
      .nav-link {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
      }
    }
  
    > {
      .container, .container-fluid {
        flex-wrap: nowrap;
      }
    }
  
    .main-header {
      display: flex !important;
      flex-basis: auto;
    }
  
    .navbar-toggler {
      display: none;
    }
  }
  
  .navbar-light {
    .navbar-brand {
      color: $black-9;
  
      &:hover, &:focus {
        color: $black-9;
      }
    }
  
    .navbar-nav {
      .nav-link {
        color: $black-5;
  
        &:hover, &:focus {
          color: $black-7;
        }
  
        &.disabled {
          color: $black-3;
        }
      }
  
      .show > .nav-link, .active > .nav-link {
        color: $black-9;
      }
  
      .nav-link {
        &.show, &.active {
          color: $black-9;
        }
      }
    }
  
    .navbar-toggler {
      color: $black-5;
      border-color: $black-1;
    }
  
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='$black-5' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  
    .navbar-text {
      color: $black-5;
  
      a {
        color: $black-9;
  
        &:hover, &:focus {
          color: $black-9;
        }
      }
    }
  }
  
  .navbar-dark {
    .navbar-brand {
      color: $white;
  
      &:hover, &:focus {
        color: $white;
      }
    }
  
    .navbar-nav {
      .nav-link {
        color: $white-5;
  
        &:hover, &:focus {
          color: rgba(255, 255, 255, 0.75);
        }
  
        &.disabled {
          color: rgba(255, 255, 255, 0.25);
        }
      }
  
      .show > .nav-link, .active > .nav-link {
        color: $white;
      }
  
      .nav-link {
        &.show, &.active {
          color: $white;
        }
      }
    }
  
    .navbar-toggler {
      color: $white-5;
      border-color: $white-1;
    }
  
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='$white-5' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    }
  
    .navbar-text {
      color: $white-5;
  
      a {
        color: $white;
  
        &:hover, &:focus {
          color: $white;
        }
      }
    }
  }
  
  /* ###### 3.10 Nav ###### */
  
  .nav-link {
    &:hover, &:focus, &:active {
      outline: none;
    }
  }
  
  .nav-pills .nav-link {
    color: $gray-700;
  
    &:hover, &:focus {
      color: $gray-900;
    }
  
    &.active {
      border-radius: 2px;
      background-color: $primary;
  
      &:hover, &:focus {
        color: $white;
      }
    }
  }
  
  .nav-dark .nav-link {
    color: $gray-500;
  
    &:hover, &:focus {
      color: $white;
    }
  }
  
  .nav-tabs {
    border-bottom-width: 0;
  
    .nav-link {
      background-color: $white-3;
      border-width: 0;
      border-radius: 0;
      padding: 10px 15px;
      line-height: 1.428;
      color: $gray-700;
  
      &:hover, &:focus {
        background-color: $white-5;
        color: $gray-900;
      }
  
      + .nav-link {
        margin-left: 3px;
      }
  
      &.active {
        background-color: $white;
        color: $gray-900;
        font-weight: 500;
        letter-spacing: -.1px;
      }
    }
  }
  
  @media (min-width: 768px) {
    .nav-tabs .nav-link {
      padding: 10px 20px;
    }
  }
  
  @media (min-width: 768px) {
    .main-nav {
      align-items: center;
    }
  }
  
  .main-nav .nav-link {
    display: block;
    color: $gray-700;
    padding: 0;
    position: relative;
    line-height: normal;
  
    &:hover, &:focus {
      color: $gray-900;
    }
  
    + .nav-link {
      padding-top: 12px;
      margin-top: 12px;
      border-top: 1px dotted $gray-500;
    }
  
    &.active {
      color: $primary;
    }
  }
  
  @media (min-width: 768px) {
    .main-nav .nav-link + .nav-link {
      padding-top: 0;
      margin-top: 0;
      border-top: 0;
      padding-left: 15px;
      margin-left: 15px;
      border-left: 1px dotted $gray-500;
    }
  }
  
  .main-nav-column {
    flex-direction: column;
  
    .nav-link {
      padding: 0;
      height: 38px;
      color: $gray-900;
      display: flex;
      align-items: center;
      justify-content: flex-start;
  
      i {
        font-size: 24px;
        line-height: 0;
        width: 24px;
        margin-right: 12px;
        text-align: center;
        transition: all 0.2s ease-in-out;
  
        &:not([class*=' tx-']) {
          color: $gray-600;
        }
  
        &.typcn {
          line-height: .9;
        }
      }
  
      span {
        font-weight: 400;
        font-size: 11px;
        color: $gray-500;
        margin-left: auto;
      }
  
      &:hover, &:focus, &:hover i:not([class*=' tx-']), &:focus i:not([class*=' tx-']) {
        color: $gray-900;
      }
  
      &.active {
        position: relative;
  
        &::before {
          content: '';
          position: absolute;
          top: 6px;
          bottom: 6px;
          left: -28px;
          width: 3px;
          background-color: $primary;
          border-radius: 3px;
          display: none;
        }
  
        color: $primary;
  
        &:hover, &:focus, i, &:hover i, &:focus i {
          color: $primary;
        }
      }
  
      + .nav-link {
        border-top: 1px dotted $gray-400;
      }
    }
  
    &.sm .nav-link {
      font-size: 0.875rem;
      font-weight: 400;
      padding: 10px 0;
  
      i {
        font-size: 21px;
      }
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-nav-column .nav-link i {
      transition: none;
    }
  }
  
  .main-nav-dark .nav-link {
    color:$white-7;
  
    &:hover, &:focus {
      color: $white;
    }
  
    + .nav-link {
      border-color: $gray-700;
    }
  
    &.active {
      color: $primary;
    }
  }
  
  .main-nav-colored-bg .nav-link {
    + .nav-link {
      border-color: $white-4;
    }
  
    &.active {
      color: $white;
    }
  }
  
  .main-nav-line {
    position: relative;
  
    .nav-link {
      padding: 0;
      color: $gray-700;
      position: relative;
  
      &:hover, &:focus {
        color: $gray-900;
      }
    }
  
    &.main-nav-dark .nav-link {
      color:$white-7;
  
      &:hover, &:focus {
        color: $white;
      }
  
      &.active {
        color: $white;
  
        &::before {
          background-color: $white;
        }
      }
    }
  }
  
  @media (min-width: 768px) {
    .example .main-nav-line .nav-link.active::before {
      content: '';
      position: absolute;
      top: auto;
      left: 0;
      right: 0;
      bottom: -15px;
      height: 2px;
      width: auto;
      background-color: $primary;
    }
  }
  
  @media (max-width: 767px) {
    .main-nav-line .nav-link.active {
      color: $white !important;
      background: $primary;
      border-radius: 2px;
    }
  
    .main-nav-line-chat {
      padding: 17px 10px  17px 20px !important;
    }
  
    .main-nav-line .nav-link {
      padding: 10px;
    }
  
    .main-content-body-profile .nav {
      padding: 20px 20px 20px 20px !important;
    }
  }
  
  .main-nav-tabs {
    padding: 15px 15px 0;
    background-color: $gray-300;
  
    .lSSlideOuter {
      position: relative;
      padding-left: 32px;
      padding-right: 35px;
    }
  
    .lSSlideWrapper {
      overflow: visible;
    }
  
    .lSAction > a {
      display: block;
      height: 40px;
      top: 16px;
      opacity: 1;
      background-color: $gray-400;
      background-image: none;
  
      &:hover, &:focus {
        background-color: #a5afc4;
      }
  
      &::before {
        font-family: 'Ionicons';
        font-size: 18px;
        position: absolute;
        top: -4px;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
  
      &.lSPrev {
        left: -32px;
  
        &::before {
          content: '\f3cf';
        }
      }
  
      &.lSNext {
        right: -35px;
  
        &::before {
          content: '\f3d1';
        }
      }
  
      &.disabled {
        background-color: $gray-200;
        color: $white;
      }
    }
  
    .lightSlider {
      display: flex;
    }
  
    .tab-item {
      flex-shrink: 0;
      display: block;
      float: none;
      min-width: 150px;
    }
  
    .tab-link {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 20px;
      line-height: 1.428;
      color: $gray-700;
      white-space: nowrap;
      background-color: $gray-200;
  
      &:hover, &:focus {
        background-color: $gray-100;
      }
  
      &.active {
        background-color: $white;
        color: $gray-900;
        font-weight: 500;
      }
    }
  }
  
  .main-tab-pane {
    display: none;
  
    &.active {
      display: block;
    }
  }
  
  /* ###### Navigation   ###### */