
/*------------------------------------------------------------------
[Master Stylesheet]

Project        :   Rapo - HTML5 Bootstrap Admin Template
Version        :   V.1
Create Date    :   10/03/20
Copyright      :   Spruko Technologies Private Limited 
Author         :   SprukoSoft
Author URL     :   https://themeforest.net/user/sprukosoft
Support	       :   support@spruko.com
License        :   Licensed under ThemeForest License

-------------------------------------------------------------------*/

/*
1.  Accordions
2.  Alerts
3.  Badges
4.  Breadcrumbs
5.  Buttons
6.  Card
7.  Carousel
8.  Dropdown
9.  Forms
10. Grid
11. Input-group
12. Modals
13. Navigation
14. Pagination
15. Popover
16. Progress
17. Tables
18. Toast
19. Tooltip
20. Main-content
21. Header
22. Horizontal-menu
23. Profile
24. Iconbar
25. Datatable
26. Date-picker
27. Date-time-picker
28. Jqvmap
29. Chart
30. Parsely
31. Rangeslider
32. Select2
33. Spectrum
34. Calendar
35. Chat
36. Contacts
37. Error
38. Invoice
39. Mail
40. Profile
41. Background
42. Border
43. Height
44. Margin
45. Opacity
46. Padding
47. Position
48. Typography
49. Width
50. Tags
51. Navbar
52. Wizard
53. P-scroll
54. Rating
55. Timeline
56. Countdown
57. Image
58. List
59. Checkbox
60  Radio
61. Toggle
62  Listgroup
63  Jumborton
64  Custom-control
65  Custom-styles
*/

@import "custom/fonts/fonts";


/* ########## BOOTSTRAP OVERRIDES ########## */
@import "variables";
@import "bootstrap/accordions";
@import "bootstrap/alerts";
@import "bootstrap/badges";
@import "bootstrap/breadcrumbs";
@import "bootstrap/buttons";
@import "bootstrap/cards";
@import "bootstrap/carousel";
@import "bootstrap/dropdown";
@import "bootstrap/forms";
@import "bootstrap/grid";
@import "bootstrap/input-group";
@import "bootstrap/modals";
@import "bootstrap/navigation";
@import "bootstrap/pagination";
@import "bootstrap/popover";
@import "bootstrap/progress";
@import "bootstrap/tables";
@import "bootstrap/toast";
@import "bootstrap/tooltip";

/* ########## LAYOUTS ########## */
@import "layouts/main-content";
@import "layouts/header";
@import "layouts/horizontal-menu";
@import "layouts/footer";
@import "layouts/sidebar";
@import "layouts/right-sidebar";
@import "layouts/profile";
@import "layouts/iconbar";

/* ############### LIB ############### */
@import "lib/datatable";
@import "lib/datepicker";
@import "lib/datetimepicker";
@import "lib/jqvmap";
@import "lib/chart";
@import "lib/parsely";
@import "lib/rangeslider";
@import "lib/select2";
@import "lib/spectrum";

/* ############### TEMPLATE STYLES ############### */
@import "template/calendar";
@import "template/chat";
@import "template/contacts";
@import "template/error";
@import "template/iconbar";
@import "template/invoice";
@import "template/mail";
@import "template/profile";

/* ############### UTILITIES/HELPER CLASSES ############### */
@import "util/background";
@import "util/border";
@import "util/height";
@import "util/margin";
@import "util/opacity";
@import "util/padding";
@import "util/position";
@import "util/typography";
@import "util/width";

/* ############### CUSTOM ELEMENT STYLES ############### */
@import "custom/tags";
@import "custom/navbar";
@import "custom/wizard";
@import "custom/p-scroll";
@import "custom/rating";
@import "custom/timeline";
@import "custom/countdown";
@import "custom/image";
@import "custom/list";
@import "custom/checkbox";
@import "custom/radio";
@import "custom/toggle";
@import "custom/listgroup";
@import "custom/jumborton";
@import "custom/custom-control";
@import "custom/custom-styles";