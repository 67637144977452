/* ###### Main-header  ###### */

@media (max-width: 991.98px) {
    .main-header-menu-show {
      overflow: hidden;
  
      .main-header-menu {
        border-right: 1px solid rgba(28, 39, 60, 0.08);
        box-shadow: 0 0 30px rgba(28, 39, 60, 0.08);
        transform: translateX(0);
      }
  
      .main-navbar-backdrop {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  
  .main-header {
    .input-group-btn .btn {
      border: 0px solid #ebeaf1 !important;
      background: transparent;
      border-radius: 3px;
      color: #3c4858;
      height: 38px;
      width: 38px;
      line-height: 24px;
      border-radius: 50px;
      font-size: 22px;
  
      &:hover {
        border-radius: 50%;
        color: $primary;
  
        i {
          color: #2205bf;
        }
      }
  
      i {
        font-size: 16px;
        color: #151e2d;
        margin-top: 2px;
      }
    }
  
    .navbar-form.active .input-group-btn .btn i {
      font-size: 14px;
      line-height: 27px;
    }
  }
  
  .navbar-form.active .input-group-btn {
    position: absolute;
    right: 12px;
    z-index: 9999;
    top: 13px;
  }
  
  .main-header form[role="search"] {
    top: 0px;
    right: 0;
    padding: 0px;
    margin: 0px;
    z-index: 0;
  
    button {
      padding: 5px 12px;
      border-radius: 0px;
      border-width: 0px;
      color: #6f7282;
      background-color: rgb(248, 248, 248);
      border-color: rgb(231, 231, 231);
      box-shadow: none;
      outline: none;
    }
  
    input {
      padding: 5px 12px;
      border-radius: 0px;
      border-width: 0px;
      color: #6f7282;
      background-color: rgb(248, 248, 248);
      border-color: rgb(231, 231, 231);
      box-shadow: none;
      outline: none;
      padding: 16px 12px;
      font-size: 14px;
      color: #807b90;
      box-shadow: none;
    }
  
    button[type="reset"] {
      display: none;
      background: $white;
      border: 0 !important;
    }
  }
  
  .main-header-center {
    .btn, .sp-container button {
      position: absolute;
      top: -1px;
      left: -35px;
      background-color: transparent;
      height: 42px;
      color: $gray-400;
      transition: none;
      font-size: 15px;
      padding-right: 13px;
      line-height: -9;
    }
  }
  
  .sp-container .main-header-center button {
    position: absolute;
    top: -1px;
    left: -35px;
    background-color: transparent;
    height: 42px;
    color: $gray-400;
    transition: none;
    font-size: 15px;
    padding-right: 13px;
    line-height: -9;
  }
  
  .main-header-message i, .main-header-notification i, .nav-item.full-screen i {
    height: 40px;
    width: 40px;
    text-align: center;
    border-radius: 50%;
    line-height: 40px;
    font-size: 21px;
  }
  
  .main-header-message > a, .main-header-notification > a, .nav-item.full-screen > a {
    display: block;
    font-size: 22px;
    color: $gray-900;
    position: relative;
    line-height: 1.5;
    outline: none;
    padding: 0.5rem 0.9rem;
  }
  
  .main-header-center {
    .form-control {
      border-color: transparent;
      border-width: 2px;
      background-color: transparent;
      transition: none;
      height: 40px;
  
      &:focus {
        background-color: $white;
        border-color: transparent;
        box-shadow: none !important;
  
        + .btn {
          color: $gray-600;
        }
      }
    }
  
    .sp-container .form-control:focus + button {
      color: $gray-600;
    }
  }
  
  .sp-container .main-header-center .form-control:focus + button {
    color: $gray-600;
  }
  
  .main-header-center {
    .btn:hover, .sp-container button:hover {
      color: $gray-900;
    }
  }
  
  .sp-container .main-header-center button:hover {
    color: $gray-900;
  }
  
  .main-header-center {
    .btn:focus, .sp-container button:focus {
      color: $gray-900;
    }
  }
  
  .sp-container .main-header-center button:focus {
    color: $gray-900;
  }
  
  .main-header {
    border-bottom: 1px solid #d5d7da;
    z-index: 999 !important;
    width: 100% !important;
    box-shadow: 0 0 10px rgba(32, 43, 64, 0.2);
  }
  
  .horizontal-mainwrapper.container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0;
    padding-left: 0;
  }
  
  .main-header {
    &.hor-header {
      > .container-fluid {
        padding-left: 25px;
        padding-right: 25px;
        width: 1200px;
      }
  
      box-shadow: none;
    }
  
    background-color: transparent;
    z-index: 100;
    height: 64px;
    background: $white;
    border-bottom: 1px solid #dee4ec;
    z-index: 999 !important;
    width: 100% !important;
    border-bottom: 1px solid #d0dce8;
    box-shadow: 0 0 10px rgba(32, 43, 64, 0.2);
  
    > {
      .container, .container-fluid {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-header > {
      .container, .container-fluid {
        padding-left: 25px;
        padding-right: 25px;
      }
    }
  }
  
  @media (max-width: 991px) {
    .main-header form[role="search"] {
      width: 38px;
  
      input {
        font-size: 16px;
        opacity: 0;
        display: none;
        height: 66px;
        transition: all 05s;
      }
  
      &.active {
        width: 100%;
        z-index: 9999;
        right: 0;
        border-left: 1px solid #e3e3f7;
        position: absolute;
        left: -1px;
  
        button {
          display: table-cell;
          opacity: 1;
        }
  
        input {
          display: table-cell;
          opacity: 1;
          width: 100%;
          height: 64px;
          background: $white;
          padding-left: 20px;
          border-radius: 3px 3px 0 0;
          transition: all 05s;
          box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.1);
        }
      }
    }
  
    .main-content-body-show .main-header-arrow {
      display: block;
      padding: 10px 20px;
      background: $primary;
      color: $white;
    }
  
    .main-header-arrow {
      &:hover, &:focus {
        color: $white !important;
      }
    }
  }
  
  @media (min-width: 1245px) {
    .main-header-center .form-control {
      border-color: transparent;
      border-width: 2px;
      background-color: transparent;
      transition: none;
      height: 40px;
      width: 500px;
    }
  }
  
  @media (min-width: 992px) {
    .main-header > .container {
      padding: 0;
    }
  }
  
  @media (max-width: 991.98px) {
    .main-header > .container {
      max-width: none;
    }
  }
  
  @media (max-width: 1158px) {
    .main-header-left #dropdownMenuButton {
      display: none;
    }
  }
  
  @media (max-width: 1052px) {
    .main-header-left #dropdownMenuButton2 {
      display: none;
    }
  }
  
  @media (max-width: 633px) {
    .profile-name.ml-2 {
      display: none;
      margin-left: 0;
    }
  
    .main-profile-menu {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-right: 14px  !important;
      padding-left: 11px !important;
    }
  }
  
  .main-profile-menu.nav-link {
    padding: 0.5rem 0.5rem;
  }
  
  .main-header .main-header-left .main-logo {
    display: none;
  }
  
  @media (min-width: 992px) {
    .main-header .main-header-left .main-logo {
      display: flex;
    }
  }
  
  .main-header-left {
    display: flex;
    align-items: center;
  
    .list-items {
      li {
        display: inline-block;
  
        i {
          display: block;
          font-size: 24px;
          color: $gray-900;
          position: relative;
          line-height: .9;
          outline: none;
          margin-right: 15px;
        }
      }
  
      margin-bottom: 0;
    }
  
    .main-header-arrow {
      display: none;
      position: relative;
      top: -2px;
    }
  }
  
  .main-logo {
    display: flex;
    align-items: center;
    position: relative;
    margin: 0 auto;
    height: 2rem;
  }
  
  .main-body {
    &.main-sidebar-hide {
      .logo-icon {
        display: block;
        height: 2.3rem;
        text-align: center;
        margin: 0 auto;
      }
  
      .main-logo {
        display: none;
      }
    }
  
    .logo-icon {
      display: none;
    }
  }
  
  .main-logo {
    &:hover, &:focus {
      color: $primary;
    }
  
    i {
      display: block;
      line-height: .9;
      margin-right: 8px;
      font-size: 40px;
  
      &::before {
        width: auto;
        height: auto;
      }
    }
  }
  
  .main-header-menu-icon {
    margin-right: 10px;
    width: 20px;
    height: 30px;
    display: flex;
    align-items: center;
  
    span {
      display: block;
      position: relative;
      width: 15px;
      height: 2px;
      background-color: $gray-900;
      border-radius: 3px;
      transition: all 0.2s ease-in-out;
  
      &::before, &::after {
        content: '';
        position: absolute;
        left: 0;
        width: 20px;
        height: 2px;
        background-color: $gray-900;
        border-radius: 3px;
        transition: all 0.2s ease-in-out;
      }
  
      &::before {
        top: -6px;
      }
  
      &::after {
        bottom: -6px;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-header-menu-icon {
      margin-right: 25px;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-header-menu-icon span {
      transition: none;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-header-menu-icon span {
      &::before, &::after {
        transition: none;
      }
    }
  }
  
  .main-header-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 240px;
    background-color: $white;
    overflow-y: auto;
    z-index: 1000;
    transform: translateX(-240px);
    transition: all 0.35s;
  
    > .nav {
      flex-direction: column;
      padding: 20px;
    }
  
    .nav-item {
      &::before {
        content: '';
        position: absolute;
        top: 67px;
        margin-left: 25px;
        width: 15px;
        height: 15px;
        border-top: 2px solid rgba(28, 39, 60, 0.12);
        border-left: 2px solid rgba(28, 39, 60, 0.12);
        background-color: $white;
        transform: rotate(45deg);
        z-index: 100;
        display: none;
      }
  
      + .nav-item {
        margin-top: 10px;
      }
  
      > .nav-link {
        display: flex;
        align-items: center;
        position: relative;
        color: $default-color;
        font-size: 15px;
        font-weight: 500;
        padding: 0;
        transition: all 0.2s ease-in-out;
  
        &:hover {
          color: $primary;
  
          i {
            color: $primary;
          }
        }
  
        &.with-sub::after {
          content: '\f3d0';
          display: inline-block;
          font-family: 'Ionicons';
          font-size: 12px;
          font-weight: 400;
          margin-left: auto;
          margin-top: 3px;
          opacity: .5;
        }
  
        .typcn {
          font-size: 20px;
          line-height: .95;
          margin-right: 15px;
          width: 16px;
  
          &::before {
            width: auto;
          }
        }
      }
  
      &.active > .nav-link {
        color: $primary;
        position: relative;
  
        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          top: 0;
          left: -20px;
          width: 2px;
          background-color: $primary;
          border-radius: 3px;
        }
      }
  
      &.show .main-menu-sub {
        display: block;
      }
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-header-menu {
      transition: none;
    }
  }
  
  @media (min-width: 992px) {
    .main-header-menu {
      background-color: transparent;
      border-right: 0;
      position: static;
      width: auto;
      overflow-y: visible;
      transform: none;
      z-index: auto;
    }
  }
  
  @media (min-width: 992px) {
    .main-header-menu > .nav {
      padding: 0;
      flex-direction: row;
      align-items: center;
    }
    .sticky-pin {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;
    }
    .sticky-pin.side-header{
	   position: sticky !important;
	   padding-left: 0px;
    }
	.main-header.side-header{
		padding-left: 0px;
	}
    .sticky {
      width: 100%;
    }
	.sidebar-mini.sidenav-toggled .main-header{
		padding-left:0px;
	}
  }

  .side-header {
    position: sticky;
    width: 100%;
    z-index: 10 !important;
    border-bottom: 1px solid #ccd6ea;
  }
  
  @media (min-width: 992px) {
    .main-header-menu .nav-item + .nav-item {
      margin-top: 0;
      margin-left: 30px;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-header-menu .nav-item > .nav-link {
      transition: none;
    }
  }
  
  @media (min-width: 992px) {
    .main-header-menu .nav-item > .nav-link {
      font-size: 0.875rem;
    }
  }
  
  @media (min-width: 992px) {
    .main-header-menu .nav-item > .nav-link.with-sub::after {
      margin-left: 5px;
    }
  }
  
  @media (min-width: 992px) {
    .main-header-menu .nav-item > .nav-link .typcn {
      font-size: 18px;
      margin-right: 7px;
    }
  }
  
  @media (min-width: 992px) {
    .main-header-menu .nav-item.active > .nav-link::before {
      top: auto;
      bottom: -22px;
      left: 0;
      right: 0;
      width: auto;
      height: 2px;
    }
  }
  
  @media (min-width: 992px) {
    .main-header-menu .nav-item.show::before {
      display: block;
    }
  }
  
  .main-header-menu-header {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid rgba(28, 39, 60, 0.05);
  
    .close {
      font-weight: 300;
      font-size: 28px;
    }
  }
  
  @media (min-width: 992px) {
    .main-header-menu-header {
      display: none;
    }
  }
  
  .main-menu-sub {
    font-size: 13px;
    margin-left: 32px;
    margin-top: 12px;
    display: none;
  
    .nav {
      flex-direction: column;
    }
  
    .nav-link {
      padding: 0;
      display: block;
      color: $gray-700;
  
      + .nav-link {
        margin-top: 6px;
        padding-top: 6px;
        border-top: 1px dotted $gray-200;
      }
  
      &:hover, &:focus {
        color: $primary;
      }
  
      &.active {
        color: $primary;
        font-weight: 500;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-menu-sub {
      position: absolute;
      top: 74px;
      background-color: $white;
      padding: 15px 20px;
      border: 2px solid rgba(28, 39, 60, 0.12);
      min-width: 200px;
      margin-left: 0;
      margin-top: 0;
    }
  }
  
  @media (min-width: 992px) {
    .main-menu-sub .nav-link.active {
      font-weight: 400;
    }
  }
  
  .main-menu-sub-mega {
    left: 0;
    right: 0;
    background-color: transparent;
    padding: 0;
    border: 0;
  
    .container {
      padding: 0;
  
      > div + div {
        margin-top: 25px;
      }
    }
  
    .nav {
      min-width: 120px;
      flex-direction: column;
  
      + .nav {
        border-top: 1px dotted $gray-200;
        margin-top: 6px;
        padding-top: 6px;
      }
  
      > span {
        display: block;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 700;
        color: $gray-900;
        letter-spacing: .5px;
        margin-bottom: 15px;
        margin-top: 30px;
  
        &:first-of-type {
          margin-top: 0;
        }
      }
    }
  }
  
  @media (max-width: 991.98px) {
    .main-menu-sub-mega .container {
      max-width: none;
    }
  }
  
  @media (min-width: 992px) {
    .main-menu-sub-mega .container {
      display: flex;
      background-color: $white;
      border: 2px solid rgba(28, 39, 60, 0.12);
    }
  }
  
  @media (min-width: 992px) {
    .main-menu-sub-mega .container > div {
      display: flex;
      padding: 20px;
      flex: 1;
      margin-top: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .main-menu-sub-mega .container > div {
      padding: 20px 25px;
    }
  }
  
  @media (min-width: 992px) {
    .main-menu-sub-mega .container > div + div {
      margin-top: 0;
      border-left: 1px solid rgba(28, 39, 60, 0.05);
    }
  }
  
  @media (min-width: 1200px) {
    .main-menu-sub-mega .nav {
      min-width: 140px;
    }
  }
  
  @media (min-width: 992px) {
    .main-menu-sub-mega .nav + .nav {
      margin-left: 20px;
      margin-top: 31px;
      padding-top: 0;
      border-top: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .main-menu-sub-mega .nav + .nav {
      margin-left: 25px;
    }
  }
  
  @media (min-width: 992px) {
    .main-menu-sub-mega .nav > span {
      font-size: 12px;
    }
  }
  
  .main-header-center {
    flex: 1;
    margin: 0 50px;
    position: relative;
    display: none;
  }
  
  @media (min-width: 992px) {
    .main-header-center {
      display: block;
    }
  }
  
  .main-header-right {
    display: flex;
    align-items: center;
  
    .btn-social {
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $gray-500;
      border-radius: 3px;
      transition: all 0.2s ease-in-out;
  
      &:hover, &:focus {
        color: $gray-700;
      }
  
      i {
        font-size: 18px;
        line-height: 0;
      }
  
      + .btn-social {
        margin-left: 10px;
      }
    }
  
    .btn-buy {
      text-transform: uppercase;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 11px;
      font-weight: 500;
      background-color: $primary;
      color: $white;
      letter-spacing: .5px;
      display: flex;
      align-items: center;
      border-radius: 3px;
      transition: all 0.2s ease-in-out;
      margin-left: 20px;
  
      &:hover, &:focus {
        background-color: #452efa;
      }
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-header-right .btn-social {
      transition: none;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-header-right .btn-buy {
      transition: none;
    }
  }
  
  .main-header-search-link {
    font-size: 18px;
    color: $gray-700;
    line-height: 1;
    margin-right: 15px;
  }
  
  .main-header-arrow {
    font-size: 27px;
    color: $gray-900;
    display: inline-block;
    line-height: 0;
  
    i {
      line-height: 0;
    }
  
    &:hover, &:focus {
      color: $gray-700;
    }
  }
  
  .main-header-message, .main-header-notification {
    position: relative;
  }
  
  .main-header-message > a, .main-header-notification > a, .nav-item.full-screen > a {
    display: block;
    font-size: 22px;
    color: $gray-900;
    position: relative;
    line-height: 1.5;
    outline: none;
    padding: 0.4rem 0.9rem;
  }
  
  .profile-name {
    margin-top: 3px;
  }
  
  .main-header-notification {
    margin-right: 0;
  
    > a::after {
      content: '';
      position: absolute;
      top: 46px;
      left: 50%;
      margin-left: -7px;
      width: 14px;
      height: 14px;
      border: 2px solid transparent;
      border-top-color: $primary;
      border-left-color: $primary;
      transform: rotate(45deg);
      background-color: $primary;
      z-index: 7;
      display: none;
    }
  }
  
  .main-header-message {
    > a::after {
      content: '';
      position: absolute;
      top: 49px;
      left: 50%;
      margin-left: -7px;
      width: 14px;
      height: 14px;
      border: 2px solid transparent;
      border-top-color: $primary;
      border-left-color: $primary;
      transform: rotate(45deg);
      background-color: $primary;
      z-index: 13;
      display: none;
    }
  
    &.right-toggle > a::after {
      border: 2px solid transparent;
      border-top-color: transparent;
      border-left-color: transparent;
      background-color: transparent;
    }
  }
  
  .main-profile-menu.show > .profile-user::after {
    content: '';
    position: absolute;
    top: 46px;
    left: 50%;
    margin-left: -7px;
    width: 14px;
    height: 14px;
    border: 2px solid transparent;
    border-top-color: $primary;
    border-left-color: $primary;
    transform: rotate(45deg);
    background-color: $primary;
    z-index: 7;
  }
  
  @media (min-width: 992px) {
    .main-header-notification > a::after, .main-header-message > a::after {
      bottom: -36px;
    }
  }
  
  .main-header-notification .dropdown-menu {
    background-color: $white;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
    border-width: 0;
    margin-top: 0;
    z-index: 900;
    border-radius: 0;
    width: 300px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -10px;
    bottom: auto;
    padding: 0;
    border: $gray-300;
    border-width: 1px;
    z-index: 9;
  }
  
  .main-header-message .dropdown-menu {
    width: 300px;
    position: absolute;
    top: 52px;
    left: auto;
    right: -10px;
    bottom: auto;
    border-color: #dde5ef;
    border-width: 0;
    z-index: 9999;
  }
  
  .main-header-notification {
    .dropdown-footer {
      text-align: center;
      padding-top: 10px;
      font-size: 13px;
      border-top: 1px dotted $gray-400;
    }
  
    &.show .dropdown-menu {
      display: block;
    }
  }
  
  @media (min-width: 576px) {
    .main-header-notification.show > a::after, .main-header-message.show > a::after, .drop-flag.show > a::after {
      display: block;
    }
  }
  
  .main-header-message.show .dropdown-menu, .drop-flag.show .dropdown-menu {
    display: block;
  }
  
  .main-notification-title {
    font-size: 18px;
    font-weight: 700;
    color: $gray-900;
    margin-bottom: 5px;
    line-height: 1;
  }
  
  .main-notification-text {
    font-size: 13px;
    margin-bottom: 15px;
  }
  
  .main-notification-list {
    .media {
      padding: 10px 0;
      position: relative;
  
      + .media {
        border-top: 1px dotted $gray-400;
      }
  
      &.new {
        color: $gray-900;
      }
  
      &:hover, &:focus {
        cursor: pointer;
        z-index: 1;
      }
  
      &:hover::before, &:focus::before {
        content: '';
        position: absolute;
        top: -1px;
        left: -20px;
        bottom: -1px;
        right: -20px;
        background-color: $gray-100;
        border-top: 1px solid $gray-300;
        border-bottom: 1px solid $gray-300;
        z-index: -1;
      }
    }
  
    .media-body {
      margin-left: 15px;
      font-size: 13px;
  
      p {
        margin-bottom: 5px;
        line-height: 1.3;
      }
  
      strong {
        font-weight: 500;
      }
  
      span {
        display: block;
        font-size: 11px;
        color: $gray-500;
      }
    }
  }
  
  /* ###### Main-header  ###### */