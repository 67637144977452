$background: #eff2f6;
$default-color:#031b4e;
$color:#e5ebf3;

/*Color variables*/
$primary:#285cf7; 
$secondary:#7987a1;
$pink:#f10075;
$teal:#00cccc;
$purple:#6f42c1;
$success:#22c03c;
$warning:#ffc107;
$danger:#dc3545;
$info:#17a2b8;
$orange:#fd7e14;
$dark:#3b4863;
$indigo:#8c0aea;
$white:#fff;
$black:#000;

/*gray variables*/
$gray-100:#eef0f5;
$gray-200:#e3e7ed;
$gray-300:#e2e8f5;
$gray-400:#b4bdce;
$gray-500:#97a3b9;
$gray-600:#7987a1;
$gray-700:#596882;
$gray-800:#394763;
$gray-900:#1c273c;

/*white variables*/
$white-1:rgba(255, 255, 255, 0.1);
$white-2:rgba(255, 255, 255, 0.2);
$white-3:rgba(255, 255, 255, 0.3);
$white-4 :rgba(255, 255, 255, 0.4);
$white-5 :rgba(255, 255, 255, 0.5);
$white-6 :rgba(255, 255, 255, 0.6);
$white-7 :rgba(255, 255, 255, 0.7);
$white-8 :rgba(255, 255, 255, 0.8);
$white-9 :rgba(255, 255, 255, 0.9);
$white-05:rgba(255, 255, 255, 0.05);
$white-08:rgba(255, 255, 255, 0.08);
$white-75:rgba(255, 255, 255, 0.075);

/*black variables*/
$black-1:rgba(0, 0, 0, 0.1);
$black-2:rgba(0, 0, 0, 0.2);
$black-3:rgba(0, 0, 0, 0.3);
$black-4:rgba(0, 0, 0, 0.4);
$black-5:rgba(0, 0, 0, 0.5);
$black-6:rgba(0, 0, 0, 0.6);
$black-7:rgba(0, 0, 0, 0.7);
$black-8:rgba(0, 0, 0, 0.8);
$black-9:rgba(0, 0, 0, 0.9);
$black-05:rgba(0, 0, 0, 0.05);

/*shadow variables*/
$shadow:0 0 10px rgba(28, 39, 60, 0.1);

$dark-theme:#2b334a;



	

