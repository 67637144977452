/* ###### Chat  ###### */

@media (max-width: 991.98px) {
    .main-content-body-show {
      .main-header-menu-icon {
        display: none;
      }
  
      .main-header-left .main-header-arrow {
        display: block;
      }
  
      .main-content-left-chat {
        display: none;
      }
  
      .main-content-body-chat {
        display: flex;
      }
    }
  }
  
  .main-content-left-chat {
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  
  .main-nav-line-chat {
    border-bottom: 1px solid $gray-300;
    padding-bottom: 5px;
    flex-shrink: 0;
    flex-direction: row;
    padding: 10px 20px;
  
    .nav-link {
      font-size: 13px;
      font-weight: 500;
      color: $dark;
      text-transform: uppercase;
  
      &:hover, &:focus, &:active {
        outline: none;
      }
  
      + .nav-link {
        margin-top: 0;
        margin-left: 30px;
      }
  
      &.active {
        color: $primary;
  
        &::before {
          top: 35px;
          left: 0;
          right: 0;
          width: auto;
          bottom: -6px;
          background-color: $primary;
        }
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-nav-line-chat {
      padding-left: 0;
      flex-shrink: 0;
      height: 55px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 0 20px;
      border-bottom: 1px solid $gray-300;
    }
  }
  
  .main-chat-contacts-wrapper {
    padding: 10px 20px;
    flex-shrink: 0;
    border-bottom: 1px solid $gray-300;
    overflow: hidden;
  
    .main-content-label, .card-table-two .card-title {
      margin-bottom: 15px;
      line-height: 1;
      padding-left: 20px;
    }
  }
  
  @media (min-width: 992px) {
    .main-chat-contacts-wrapper {
      padding: 10px 15px 10px 0;
    }
  }
  
  .card-table-two .main-chat-contacts-wrapper .card-title, .main-chat-contacts-wrapper .card-dashboard-eight .card-title, .card-dashboard-eight .main-chat-contacts-wrapper .card-title {
    margin-bottom: 15px;
    line-height: 1;
    padding-left: 20px;
  }
  
  .main-chat-contacts-wrapper {
    .lSSlideOuter, .lSSlideWrapper {
      overflow: visible;
    }
  }
  
  .main-chat-contacts {
    padding-left: 20px;
    display: flex;
  
    .lslide {
      display: flex;
      flex-direction: column;
      align-items: center;
      float: none;
    }
  
    small {
      font-size: 11px;
      width: 36px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      margin-top: 3px;
      text-align: center;
    }
  }
  
  .main-chat-contacts-more {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: $gray-300;
    font-size: 12px;
    font-weight: 500;
    color: $white;
  }
  
  @media (min-width: 992px) {
    .main-chat-list {
      max-height: 490px;
      position: relative;
    }
  }
  
  .main-chat-list {
    .media {
      padding: 12px 20px;
      border-left: 0;
      border-right: 0;
      border-bottom: 1px solid $gray-300;
      position: relative;
  
      .main-img-user {
        flex-shrink: 0;
        top: 3px;
      }
  
      &::after {
        content: '';
        position: absolute;
        top: -1px;
        bottom: -1px;
        left: -1px;
        width: 1px;
        background-color: $primary;
        display: none;
      }
  
      + .media::before {
        content: '';
        position: absolute;
        top: -1px;
        left: 65px;
        right: 0;
      }
  
      &.new {
        background-color: $white;
  
        .main-img-user span {
          display: flex;
        }
  
        .media-contact-name span:first-child {
          font-weight: 700;
          color: $gray-900;
        }
  
        .media-body p {
          color: $default-color;
        }
      }
  
      &:hover, &:focus {
        cursor: pointer;
        background-color: #fcfcfc;
        border-top-color: $gray-200;
        border-bottom-color: $gray-200;
        z-index: 1;
      }
  
      &:hover:first-child, &:focus:first-child {
        border-top-color: transparent;
      }
  
      &.selected {
        background-color: #f3f6fb;
        border-top-color: $gray-200;
        border-bottom-color: $gray-200;
        border-left: 1px solid $primary;
        z-index: 2;
  
        &:first-child {
          border-top-color: transparent;
        }
  
        &::after {
          display: block;
        }
  
        .media-contact-name span:first-child {
          color: $gray-900;
        }
  
        .media-body p {
          color: $default-color;
        }
      }
    }
  
    .main-img-user span {
      position: absolute;
      top: 0;
      left: -2px;
      width: 14px;
      height: 14px;
      display: none;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      font-size: 9px;
      font-weight: 500;
      color: $white;
      background-color: $danger;
      box-shadow: 0 0 0 2px $white;
    }
  
    .media-body {
      margin-left: 15px;
  
      p {
        font-size: 13px;
        margin-bottom: 0;
        color: $gray-600;
        line-height: 1.35;
      }
    }
  
    .media-contact-name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 2px;
  
      span {
        &:first-child {
          display: block;
          font-size: 14px;
          font-weight: 500;
          color: $gray-900;
        }
  
        &:last-child {
          display: block;
          font-size: 11px;
          color: $gray-500;
        }
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-chat-list .media {
      padding: 12px 15px;
    }
  }
  
  .main-content-body-chat {
    flex-direction: column;
    display: none;
  }
  
  @media (min-width: 992px) {
    .main-content-body-chat {
      display: flex;
    }
  }
  
  .main-chat-header {
    flex-shrink: 0;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    border-bottom: 1px solid $gray-300;
  
    .nav {
      margin-left: auto;
      align-items: center;
    }
  
    .nav-link {
      padding: 0;
      color: #b8c2d8;
      font-size: 20px;
      display: none;
  
      &:first-child {
        display: block;
      }
  
      i {
        line-height: 0;
      }
  
      .typcn {
        line-height: .9;
  
        &::before {
          width: auto;
        }
      }
  
      &:hover, &:focus {
        color: $primary;
      }
  
      + .nav-link {
        margin-left: 15px;
      }
    }
  }
  
  @media (min-width: 576px) {
    .main-chat-header .nav-link {
      display: block;
  
      &:first-child {
        display: none;
      }
    }
  }
  
  .main-chat-msg-name {
    margin-left: 15px;
  
    h6 {
      margin-bottom: 2px;
      font-size: 15px;
      font-weight: 700;
      color: $gray-900;
    }
  
    small {
      font-size: 12px;
      color: $gray-600;
    }
  }
  
  .main-chat-body {
    position: relative;
    padding-bottom: 50px;
  
    .content-inner {
      padding: 20px;
    }
  
    .media {
      + .media {
        margin-top: 20px;
      }
  
      &.flex-row-reverse {
        .media-body {
          margin-left: 0;
          margin-right: 20px;
          align-items: flex-end;
        }
  
        .main-msg-wrapper {
          background-color: $primary;
          color: $white;
        }
      }
    }
  
    .media-body {
      margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
  
      > div:last-child {
        font-size: 11px;
        color: $gray-600;
        display: flex;
        align-items: center;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-chat-body {
      padding-bottom: 0;
      max-height: 535px;
      overflow: hidden;
    }
  }
  
  @media (min-width: 576px) {
    .main-chat-body .media.flex-row-reverse .media-body {
      margin-left: 55px;
    }
  }
  
  @media (min-width: 576px) {
    .main-chat-body .media-body {
      margin-right: 55px;
    }
  }
  
  .main-chat-time {
    display: block;
    position: relative;
    text-align: center;
    margin: 20px 0;
  
    &:first-of-type {
      margin-top: 0;
    }
  
    span {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
  }
  
  .main-msg-wrapper {
    padding: 10px 15px;
    background-color: $gray-300;
    font-size: 13px;
    margin-bottom: 5px;
    border-radius: 2px;
    display: inline-block;
  }
  
  .main-chat-footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px;
    border-top: 1px solid $gray-300;
    background-color: $white;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  
    .nav {
      align-items: center;
    }
  
    .nav-link {
      padding: 0;
      color: $gray-500;
      font-size: 16px;
      display: none;
  
      + .nav-link {
        margin-left: 10px;
      }
  
      &:last-child {
        display: block;
        margin-left: 0;
      }
    }
  
    .form-control {
      flex: 1;
      margin: 0 10px;
      padding-left: 0;
      padding-right: 0;
      border-width: 0;
  
      &:hover, &:focus {
        box-shadow: none;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-chat-footer {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
    }
  }
  
  @media (min-width: 576px) {
    .main-chat-footer .nav-link {
      display: block;
  
      &:last-child {
        display: none;
      }
    }
  }
  
  @media (min-width: 576px) {
    .main-chat-footer .form-control {
      margin-left: 20px;
    }
  }
  
  @media (min-width: 768px) {
    .main-chat-footer .form-control {
      margin: 0 20px;
    }
  }
  
  .main-msg-send {
    font-size: 20px;
    color: $primary;
  }
  
  /* ###### Chat  ###### */