/* ###### Form-elements ###### */

.form-control {
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    color: $gray-700;
    background-color: $white;
    background-clip: padding-box;
    border: 1px solid $gray-300;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  
    &::-ms-expand {
      background-color: transparent;
      border: 0;
    }
  
    &:focus {
      color: $gray-700;
      background-color: $white;
      border-color: #b3c6ff;
      outline: 0;
      box-shadow: 0 0 0 0.2rem rgba(51, 102, 255, 0.25);
    }
  
    &::placeholder {
      color: $gray-400;
      opacity: 1;
    }
  
    &:disabled, &[readonly] {
      background-color: $gray-200;
      opacity: 1;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .form-control {
      transition: none;
    }
  }
  
  select.form-control:focus::-ms-value {
    color: $gray-700;
    background-color: $white;
  }
  
  .form-control-file, .form-control-range {
    display: block;
    width: 100%;
  }
  
  .col-form-label {
    padding-top: calc(0.375rem + 1px);
    padding-bottom: calc(0.375rem + 1px);
    margin-bottom: 0;
    font-size: inherit;
    line-height: 1.5;
  }
  
  .col-form-label-lg {
    padding-top: calc(0.5rem + 1px);
    padding-bottom: calc(0.5rem + 1px);
    font-size: 1.09375rem;
    line-height: 1.5;
  }
  
  .col-form-label-sm {
    padding-top: calc(0.25rem + 1px);
    padding-bottom: calc(0.25rem + 1px);
    font-size: 0.76562rem;
    line-height: 1.5;
  }
  
  .form-control-plaintext {
    display: block;
    width: 100%;
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
    margin-bottom: 0;
    line-height: 1.5;
    color: $default-color;
    background-color: transparent;
    border: solid transparent;
    border-width: 1px 0;
  
    &.form-control-sm, &.form-control-lg {
      padding-right: 0;
      padding-left: 0;
    }
  }
  
  .form-control-sm {
    height: calc(1.5em + 0.5rem + 2px);
    padding: 0.25rem 0.5rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    border-radius: 0.2rem;
  }
  
  .form-control-lg {
    height: calc(1.5em + 1rem + 2px);
    padding: 0.5rem 1rem;
    font-size: 1.09375rem;
    line-height: 1.5;
    border-radius: 0.3rem;
  }
  
  select.form-control {
    &[size], &[multiple] {
      height: auto;
    }
  }
  
  textarea.form-control {
    height: auto;
  }
  
  .form-group {
    margin-bottom: 1rem;
  }
  
  .form-text {
    display: block;
    margin-top: 0.25rem;
  }
  
  .form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -5px;
    margin-left: -5px;
  
    > {
      .col, [class*="col-"] {
        padding-right: 5px;
        padding-left: 5px;
      }
    }
  }
  
  .form-check {
    position: relative;
    display: block;
    padding-left: 1.25rem;
  }
  
  .form-check-input {
    position: absolute;
    margin-top: 0.3rem;
    margin-left: -1.25rem;
  
    &:disabled ~ .form-check-label {
      color: $gray-600;
    }
  }
  
  .form-check-label {
    margin-bottom: 0;
  }
  
  .form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0;
    margin-right: 0.75rem;
  
    .form-check-input {
      position: static;
      margin-top: 0;
      margin-right: 0.3125rem;
      margin-left: 0;
    }
  }
  
  .valid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $success;
  }
  
  .form-control {
    height: 40px;
    border-radius: 0;
  
    &:focus {
      border-color: $gray-500;
      box-shadow: none;
    }
  }
  
  .custom-file, .custom-file-input {
    height: 38px;
  }
  
  .custom-file-label {
    height: 38px;
    line-height: 1.8;
    border-radius: 0;
  
    &::after {
      line-height: 1.8;
      border-radius: 0;
      height: auto;
    }
  }
  
  .form-label {
    display: block;
    margin-bottom: 5px;
    color: $gray-700;
  }
  
  .was-validated .form-control:valid, .form-control.is-valid, input.form-control.parsley-success, textarea.form-control.parsley-success {
    border-color: $success;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300cccc ' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  
  .was-validated .form-control:valid:focus, .form-control.is-valid:focus, input.form-control.parsley-success:focus, textarea.form-control.parsley-success:focus {
    border-color: $success;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
  }
  
  .was-validated .form-control:valid ~ {
    .valid-feedback, .valid-tooltip {
      display: block;
    }
  }
  
  .form-control.is-valid ~ .valid-feedback, input.form-control.parsley-success ~ .valid-feedback, textarea.form-control.parsley-success ~ .valid-feedback, .form-control.is-valid ~ .valid-tooltip, input.form-control.parsley-success ~ .valid-tooltip, textarea.form-control.parsley-success ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated textarea.form-control:valid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  }
  
  textarea.form-control {
    &.is-valid, &.parsley-success {
      padding-right: calc(1.5em + 0.75rem);
      background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
    }
  }
  
  .was-validated .custom-select:valid, .custom-select.is-valid, input.custom-select.parsley-success, textarea.custom-select.parsley-success {
    border-color: $success;
    padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem (center / 8px) 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2300cccc ' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") $white no-repeat center right (1.75rem / calc(0.75em + 0.375rem)) calc(0.75em + 0.375rem);
  }
  
  .was-validated .custom-select:valid:focus, .custom-select.is-valid:focus, input.custom-select.parsley-success:focus, textarea.custom-select.parsley-success:focus {
    border-color: $success;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
  }
  
  .was-validated .custom-select:valid ~ {
    .valid-feedback, .valid-tooltip {
      display: block;
    }
  }
  
  .custom-select.is-valid ~ .valid-feedback, input.custom-select.parsley-success ~ .valid-feedback, textarea.custom-select.parsley-success ~ .valid-feedback, .custom-select.is-valid ~ .valid-tooltip, input.custom-select.parsley-success ~ .valid-tooltip, textarea.custom-select.parsley-success ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .form-control-file:valid ~ {
    .valid-feedback, .valid-tooltip {
      display: block;
    }
  }
  
  .form-control-file.is-valid ~ .valid-feedback, input.form-control-file.parsley-success ~ .valid-feedback, textarea.form-control-file.parsley-success ~ .valid-feedback, .form-control-file.is-valid ~ .valid-tooltip, input.form-control-file.parsley-success ~ .valid-tooltip, textarea.form-control-file.parsley-success ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label, input.form-check-input.parsley-success ~ .form-check-label, textarea.form-check-input.parsley-success ~ .form-check-label {
    color: $success;
  }
  
  .was-validated .form-check-input:valid ~ {
    .valid-feedback, .valid-tooltip {
      display: block;
    }
  }
  
  .form-check-input.is-valid ~ .valid-feedback, input.form-check-input.parsley-success ~ .valid-feedback, textarea.form-check-input.parsley-success ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, input.form-check-input.parsley-success ~ .valid-tooltip, textarea.form-check-input.parsley-success ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label, input.custom-control-input.parsley-success ~ .custom-control-label, textarea.custom-control-input.parsley-success ~ .custom-control-label {
    color: $success;
  }
  
  .was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before, input.custom-control-input.parsley-success ~ .custom-control-label::before, textarea.custom-control-input.parsley-success ~ .custom-control-label::before {
    border-color: $success;
  }
  
  .was-validated .custom-control-input:valid ~ {
    .valid-feedback, .valid-tooltip {
      display: block;
    }
  }
  
  .custom-control-input.is-valid ~ .valid-feedback, input.custom-control-input.parsley-success ~ .valid-feedback, textarea.custom-control-input.parsley-success ~ .valid-feedback, .custom-control-input.is-valid ~ .valid-tooltip, input.custom-control-input.parsley-success ~ .valid-tooltip, textarea.custom-control-input.parsley-success ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before, input.custom-control-input.parsley-success:checked ~ .custom-control-label::before, textarea.custom-control-input.parsley-success:checked ~ .custom-control-label::before {
    border-color: #4ce301;
    background-color: #4ce301;
  }
  
  .was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before, input.custom-control-input.parsley-success:focus ~ .custom-control-label::before, textarea.custom-control-input.parsley-success:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
  }
  
  .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before, input.custom-control-input.parsley-success:focus:not(:checked) ~ .custom-control-label::before, textarea.custom-control-input.parsley-success:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label, input.custom-file-input.parsley-success ~ .custom-file-label, textarea.custom-file-input.parsley-success ~ .custom-file-label {
    border-color: $success;
  }
  
  .was-validated .custom-file-input:valid ~ {
    .valid-feedback, .valid-tooltip {
      display: block;
    }
  }
  
  .custom-file-input.is-valid ~ .valid-feedback, input.custom-file-input.parsley-success ~ .valid-feedback, textarea.custom-file-input.parsley-success ~ .valid-feedback, .custom-file-input.is-valid ~ .valid-tooltip, input.custom-file-input.parsley-success ~ .valid-tooltip, textarea.custom-file-input.parsley-success ~ .valid-tooltip {
    display: block;
  }
  
  .was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label, input.custom-file-input.parsley-success:focus ~ .custom-file-label, textarea.custom-file-input.parsley-success:focus ~ .custom-file-label {
    border-color: $success;
    box-shadow: 0 0 0 0.2rem rgba(59, 176, 1, 0.25);
  }
  
  .invalid-feedback {
    display: none;
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $danger;
  }
  
  .invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    padding: 0.25rem 0.5rem;
    margin-top: .1rem;
    font-size: 0.76562rem;
    line-height: 1.5;
    color: $white;
    background-color: rgba(220, 53, 69, 0.9);
    border-radius: 3px;
  }
  
  .was-validated .form-control:invalid, .form-control.is-invalid, input.form-control.parsley-error, textarea.form-control.parsley-error {
    border-color: $danger;
    padding-right: calc(1.5em + 0.75rem);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
    background-repeat: no-repeat;
    background-position: center right calc(0.375em + 0.1875rem);
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
  }
  
  .was-validated .form-control:invalid:focus, .form-control.is-invalid:focus, input.form-control.parsley-error:focus, textarea.form-control.parsley-error:focus {
    border-color: $danger;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .was-validated .form-control:invalid ~ {
    .invalid-feedback, .invalid-tooltip {
      display: block;
    }
  }
  
  .form-control.is-invalid ~ .invalid-feedback, input.form-control.parsley-error ~ .invalid-feedback, textarea.form-control.parsley-error ~ .invalid-feedback, .form-control.is-invalid ~ .invalid-tooltip, input.form-control.parsley-error ~ .invalid-tooltip, textarea.form-control.parsley-error ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated textarea.form-control:invalid {
    padding-right: calc(1.5em + 0.75rem);
    background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
  }
  
  textarea.form-control {
    &.is-invalid, &.parsley-error {
      padding-right: calc(1.5em + 0.75rem);
      background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
    }
  }
  
  .was-validated .custom-select:invalid, .custom-select.is-invalid, input.custom-select.parsley-error, textarea.custom-select.parsley-error {
    border-color: $danger;
    padding-right: calc((1em + 0.75rem) * 3 / 4 + 1.75rem);
    background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%233b4863' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 0.75rem (center / 8px) 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") $white no-repeat center right (1.75rem / calc(0.75em + 0.375rem)) calc(0.75em + 0.375rem);
  }
  
  .was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus, input.custom-select.parsley-error:focus, textarea.custom-select.parsley-error:focus {
    border-color: $danger;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .was-validated .custom-select:invalid ~ {
    .invalid-feedback, .invalid-tooltip {
      display: block;
    }
  }
  
  .custom-select.is-invalid ~ .invalid-feedback, input.custom-select.parsley-error ~ .invalid-feedback, textarea.custom-select.parsley-error ~ .invalid-feedback, .custom-select.is-invalid ~ .invalid-tooltip, input.custom-select.parsley-error ~ .invalid-tooltip, textarea.custom-select.parsley-error ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .form-control-file:invalid ~ {
    .invalid-feedback, .invalid-tooltip {
      display: block;
    }
  }
  
  .form-control-file.is-invalid ~ .invalid-feedback, input.form-control-file.parsley-error ~ .invalid-feedback, textarea.form-control-file.parsley-error ~ .invalid-feedback, .form-control-file.is-invalid ~ .invalid-tooltip, input.form-control-file.parsley-error ~ .invalid-tooltip, textarea.form-control-file.parsley-error ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label, input.form-check-input.parsley-error ~ .form-check-label, textarea.form-check-input.parsley-error ~ .form-check-label {
    color: $danger;
  }
  
  .was-validated .form-check-input:invalid ~ {
    .invalid-feedback, .invalid-tooltip {
      display: block;
    }
  }
  
  .form-check-input.is-invalid ~ .invalid-feedback, input.form-check-input.parsley-error ~ .invalid-feedback, textarea.form-check-input.parsley-error ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, input.form-check-input.parsley-error ~ .invalid-tooltip, textarea.form-check-input.parsley-error ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label, input.custom-control-input.parsley-error ~ .custom-control-label, textarea.custom-control-input.parsley-error ~ .custom-control-label {
    color: $danger;
  }
  
  .was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before, input.custom-control-input.parsley-error ~ .custom-control-label::before, textarea.custom-control-input.parsley-error ~ .custom-control-label::before {
    border-color: $danger;
  }
  
  .was-validated .custom-control-input:invalid ~ {
    .invalid-feedback, .invalid-tooltip {
      display: block;
    }
  }
  
  .custom-control-input.is-invalid ~ .invalid-feedback, input.custom-control-input.parsley-error ~ .invalid-feedback, textarea.custom-control-input.parsley-error ~ .invalid-feedback, .custom-control-input.is-invalid ~ .invalid-tooltip, input.custom-control-input.parsley-error ~ .invalid-tooltip, textarea.custom-control-input.parsley-error ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before, input.custom-control-input.parsley-error:checked ~ .custom-control-label::before, textarea.custom-control-input.parsley-error:checked ~ .custom-control-label::before {
    border-color: #e4606d;
    background-color: #e4606d;
  }
  
  .was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before, input.custom-control-input.parsley-error:focus ~ .custom-control-label::before, textarea.custom-control-input.parsley-error:focus ~ .custom-control-label::before {
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before, input.custom-control-input.parsley-error:focus:not(:checked) ~ .custom-control-label::before, textarea.custom-control-input.parsley-error:focus:not(:checked) ~ .custom-control-label::before, .was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label, input.custom-file-input.parsley-error ~ .custom-file-label, textarea.custom-file-input.parsley-error ~ .custom-file-label {
    border-color: $danger;
  }
  
  .was-validated .custom-file-input:invalid ~ {
    .invalid-feedback, .invalid-tooltip {
      display: block;
    }
  }
  
  .custom-file-input.is-invalid ~ .invalid-feedback, input.custom-file-input.parsley-error ~ .invalid-feedback, textarea.custom-file-input.parsley-error ~ .invalid-feedback, .custom-file-input.is-invalid ~ .invalid-tooltip, input.custom-file-input.parsley-error ~ .invalid-tooltip, textarea.custom-file-input.parsley-error ~ .invalid-tooltip {
    display: block;
  }
  
  .was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label, input.custom-file-input.parsley-error:focus ~ .custom-file-label, textarea.custom-file-input.parsley-error:focus ~ .custom-file-label {
    border-color: $danger;
    box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
  }
  
  .form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
  
    .form-check {
      width: 100%;
    }
  }
  
  @media (min-width: 576px) {
    .form-inline {
      label {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
      }
  
      .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center;
        margin-bottom: 0;
      }
  
      .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle;
      }
  
      .form-control-plaintext {
        display: inline-block;
      }
  
      .input-group, .custom-select {
        width: auto;
      }
  
      .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto;
        padding-left: 0;
      }
  
      .form-check-input {
        position: relative;
        flex-shrink: 0;
        margin-top: 0;
        margin-right: 0.25rem;
        margin-left: 0;
      }
  
      .custom-control {
        align-items: center;
        justify-content: center;
      }
  
      .custom-control-label {
        margin-bottom: 0;
      }
    }
  }
  
  .fade {
    transition: opacity 0.15s linear;
  
    &:not(.show) {
      opacity: 0;
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .fade {
      transition: none;
    }
  }
  
  .collapse:not(.show) {
    display: none;
  }
  
  .collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .collapsing {
      transition: none;
    }
  }
  
  /* ###### Form-elements ###### */