/* ###### Progress ###### */

.progress {
    display: flex;
    height: 1rem;
    overflow: hidden;
    font-size: 0.65625rem;
    background-color: $gray-200;
    border-radius: 3px;
  }
  
  .progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: $white;
    text-align: center;
    white-space: nowrap;
    background-color: $primary;
    transition: width 0.6s ease;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar {
      transition: none;
    }
  }
  
  .progress-bar-striped {
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    background-size: 1rem 1rem;
  }
  
  .progress-bar-animated {
    animation: progress-bar-stripes 1s linear infinite;
  }
  
  .progress {
    height: auto;
    border-radius: 0;
  }
  
  .progress-bar {
    border-radius: 0;
    height: 10px;
    font-size: 11px;
  }
  
  .progress-bar-xs {
    height: 5px;
  }
  
  .progress-bar-sm {
    height: 8px;
  }
  
  .progress-bar-lg {
    height: 15px;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .progress-bar-animated {
      animation: none;
    }
  }
  
  @keyframes progress-bar-stripes {
    from {
      background-position: 1rem 0;
    }
  
    to {
      background-position: 0 0;
    }
  }
  
  /* ###### Progress ###### */