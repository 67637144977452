/* ###### Toggle ###### */

.main-toggle {
    width: 60px;
    height: 25px;
    background-color: $gray-400;
    padding: 2px;
    position: relative;
    overflow: hidden;
  
    span {
      position: absolute;
      top: 3px;
      bottom: 3px;
      left: 3px;
      display: block;
      width: 20px;
      background-color: $white;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
  
      &::before, &::after {
        position: absolute;
        font-size: 10px;
        font-weight: 500;
        letter-spacing: .5px;
        text-transform: uppercase;
        color: $white;
        top: 2px;
        line-height: 1.38;
      }
  
      &::before {
        content: 'on';
        left: -25px;
      }
  
      &::after {
        content: 'off';
        right: -29px;
      }
    }
  
    &.on {
      background-color: $primary;
  
      span {
        left: 37px;
      }
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-toggle span {
      transition: none;
    }
  }
  
  .main-toggle-secondary.on {
    background-color: $primary;
  }
  
  .main-toggle-success.on {
    background-color: $success;
  }
  
  .main-toggle-dark.on {
    background-color: $gray-900;
  }
  
  .main-form-group {
    padding: 12px 15px;
    border: 1px solid $gray-300;
  
    &.focus {
      border-color: $gray-400;
      box-shadow: 0 0 0 2px rgba(91, 71, 251, 0.16);
    }
  
    .form-label {
      font-size: 13px;
      margin-bottom: 2px;
      font-weight: 400;
      color: $gray-500;
    }
  
    .form-control {
      padding: 0;
      border-width: 0;
      height: 25px;
      color: $gray-900;
      font-weight: 500;
  
      &:focus {
        box-shadow: none;
      }
    }
  }
  
  /* ###### Toggle ###### */