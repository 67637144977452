/* ###### Card  ###### */

.card {
    border-radius: 0;
  
    &.text-white .card-title, &.tx-white .card-title {
      color: $white;
    }
  }
  
  .card-header, .card-footer {
    position: relative;
    border-color: $gray-300;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    padding-top: 1.25rem;
  }
  
  .card-header {
    border-bottom: 0;
  
    &:first-child {
      border-radius: 0;
    }
  }
  
  .card-header-tab {
    border-bottom: 0;
    padding: 0;
  }
  
  .card-title {
    font-weight: 700;
    color: $gray-900;
    font-size: 14px;
    text-transform: uppercase;
  }
  
  .card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    background: $white;
    background-clip: border-box;
    border-radius: 3px;
    border: 0 !important;
    margin-bottom: 1.3rem;
    box-shadow: $shadow;
    border: 1px solid #d8e0e7 !important;
  
    > hr {
      margin-right: 0;
      margin-left: 0;
    }
  }
  
  .card-options {
    margin-left: auto;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-order: 100;
    order: 100;
    margin-right: -.5rem;
    color: #8c98b9;
    -ms-flex-item-align: center;
    align-self: center;
  
    a:not(.btn) {
      margin-left: .5rem;
      color: #b0bcd0;
      font-size: 14px;
      display: inline-block;
      min-width: 1rem;
    }
  }
  
  .card > .list-group {
    &:first-child .list-group-item:first-child {
      border-top-left-radius: 3px;
      border-top-right-radius: 3px;
    }
  
    &:last-child .list-group-item:last-child {
      border-bottom-right-radius: 3px;
      border-bottom-left-radius: 3px;
    }
  }
  
  .card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
  }
  
  .card-title {
    margin-bottom: 0.75rem;
  }
  
  .card-subtitle {
    margin-top: -0.375rem;
    margin-bottom: 0;
  }
  
  .card-text:last-child {
    margin-bottom: 0;
  }
  
  .card-link {
    &:hover {
      text-decoration: none;
    }
  
    + .card-link {
      margin-left: 1.25rem;
    }
  }
  
  .card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgb(255, 255, 255);
    border-bottom: 0;
  
    &:first-child {
      border-radius: calc(3px - 1px) calc(3px - 1px) 0 0;
    }
  
    + .list-group .list-group-item:first-child {
      border-top: 0;
    }
  }
  
  .card-footer {
    padding: 0.75rem 1.25rem;
    background-color: rgb(241, 245, 251);
    border-top: 1px solid rgb(225, 235, 251);
  
    &:last-child {
      border-radius: 0 0 calc(3px - 1px) calc(3px - 1px);
    }
  }
  
  .card-header-tabs {
    margin-right: -0.625rem;
    margin-bottom: -0.75rem;
    margin-left: -0.625rem;
    border-bottom: 0;
  }
  
  .card-header-pills {
    margin-right: -0.625rem;
    margin-left: -0.625rem;
  }
  
  .card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 1.25rem;
  }
  
  .card-img {
    width: 100%;
    border-radius: calc(3px - 1px);
  }
  
  .card-img-top {
    width: 100%;
    border-top-left-radius: calc(3px - 1px);
    border-top-right-radius: calc(3px - 1px);
  }
  
  .card-img-bottom {
    width: 100%;
    border-bottom-right-radius: calc(3px - 1px);
    border-bottom-left-radius: calc(3px - 1px);
  }
  
  .card-deck {
    display: flex;
    flex-direction: column;
  
    .card {
      margin-bottom: 15px;
    }
  }
  
  @media (min-width: 576px) {
    .card-deck {
      flex-flow: row wrap;
      margin-right: -15px;
      margin-left: -15px;
  
      .card {
        display: flex;
        flex: 1 0 0%;
        flex-direction: column;
        margin-right: 15px;
        margin-bottom: 0;
        margin-left: 15px;
      }
    }
  }
  
  .card-group {
    display: flex;
    flex-direction: column;
  
    > .card {
      margin-bottom: 15px;
    }
  }
  
  @media (min-width: 576px) {
    .card-group {
      flex-flow: row wrap;
  
      > .card {
        flex: 1 0 0%;
        margin-bottom: 0;
  
        + .card {
          margin-left: 0;
          border-left: 0;
        }
  
        &:not(:last-child) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
  
          .card-img-top, .card-header {
            border-top-right-radius: 0;
          }
  
          .card-img-bottom, .card-footer {
            border-bottom-right-radius: 0;
          }
        }
  
        &:not(:first-child) {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
  
          .card-img-top, .card-header {
            border-top-left-radius: 0;
          }
  
          .card-img-bottom, .card-footer {
            border-bottom-left-radius: 0;
          }
        }
      }
    }
  }
  
  .card-columns .card {
    margin-bottom: 0.75rem;
  }
  
  @media (min-width: 576px) {
    .card-columns {
      column-count: 3;
      column-gap: 1.25rem;
      orphans: 1;
      widows: 1;
  
      .card {
        display: inline-block;
        width: 100%;
      }
    }
  }
  
  /* ###### Card  ###### */