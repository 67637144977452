/* ###### Grid ###### */

.row-sm {
    margin-left: -10px;
    margin-right: -10px;
  
    > div {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  
  .row-xs {
    margin-left: -5px;
    margin-right: -5px;
  
    > div {
      padding-left: 5px;
      padding-right: 5px;
    }
  }
  
  @media (min-width: 576px) {
    .row-xs--sm {
      margin-left: -5px;
      margin-right: -5px;
  
      > div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  
    .row-sm--sm {
      margin-left: -10px;
      margin-right: -10px;
  
      > div {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  
    .row--sm {
      margin-left: -15px;
      margin-right: -15px;
  
      > div {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  
  @media (min-width: 768px) {
    .row-xs--md {
      margin-left: -5px;
      margin-right: -5px;
  
      > div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  
    .row-sm--md {
      margin-left: -10px;
      margin-right: -10px;
  
      > div {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  
    .row--md {
      margin-left: -15px;
      margin-right: -15px;
  
      > div {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  
  @media (min-width: 992px) {
    .row-xs--lg {
      margin-left: -5px;
      margin-right: -5px;
  
      > div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  
    .row-sm--lg {
      margin-left: -10px;
      margin-right: -10px;
  
      > div {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  
    .row--lg {
      margin-left: -15px;
      margin-right: -15px;
  
      > div {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  
  @media (min-width: 1200px) {
    .row-xs--xl {
      margin-left: -5px;
      margin-right: -5px;
  
      > div {
        padding-left: 5px;
        padding-right: 5px;
      }
    }
  
    .row-sm--xl {
      margin-left: -10px;
      margin-right: -10px;
  
      > div {
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  
    .row--xl {
      margin-left: -15px;
      margin-right: -15px;
  
      > div {
        padding-left: 15px;
        padding-right: 15px;
      }
    }
  }
  
  /*--- COMPONENT COLS & ROWS ---*/
  
  .input-group-prepend, .input-group-append {
    border-radius: 0;
  }
  
  .input-group-text {
    padding-top: 0;
    padding-bottom: 0;
    align-items: center;
    border-radius: 0;
  }
  
  /* ###### Grid ###### */