/* ###### Sidebar  ###### */
@media (max-width: 991.98px) {
    .main-sidebar-show {
      overflow: hidden;
  
      .main-sidebar {
        display: flex;
        overflow-y: auto;
        transform: translateX(0);
        visibility: visible;
      }
  
      .main-content {
        transform: translateX(240px);
      }
  
      .main-navbar-backdrop {
        visibility: visible;
        opacity: 1;
      }
    }
  }
  
  .main-body-sidebar {
    display: block;
  }
  
  .main-sidebar-body .nav-sub-item {
    margin-left: 55px;
  }
  
  @media (min-width: 992px) {
    .main-body-sidebar {
      display: flex;
      flex-direction: row;
    }
  }
  
  .main-body-sidebar {
    .main-content {
      flex: 1;
      padding: 0;
      display: flex;
      flex-direction: column;
      transition: margin-left 0.3s ease;
    }
  
    .main-content-header {
      height: auto;
      padding: 15px 20px;
      background: $white;
      margin: 21px;
      border: 1px solid #e3e8f1;
    }
  
    .main-footer {
      margin-top: auto;
    }
  }
  
  @media (min-width: 992px) {
    .main-body-sidebar .main-header .container-fluid {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  
  .main-sidebar {
    width: 240px;
    flex-direction: column;
    flex-shrink: 0;
    border-right: 0 solid #d8dfea;
    transform: translateX(-240px);
    visibility: hidden;
    transition: all 0.4s;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-sidebar {
      transition: none;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
    .main-sidebar {
      width: 240px;
    }
  
    .main-sidebar-header {
      width: 240px !important;
    }
  }
  
  @media (max-width: 991.98px) {
    .main-sidebar {
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1000;
      background-color: $white;
    }
  }
  
  @media (min-width: 992px) {
    .main-sidebar {
      position: fixed;
      display: flex;
      transform: none;
      z-index: 99;
      border-right-width: 1px;
      visibility: visible;
      background: $white;
      transition: left 0.3s ease, width 0.3s ease;
    }
  
    .main-sidebar-header {
      z-index: 999;
    }
  
    .main-sidebar-hide .main-sidebar-body .nav-link {
      border-radius: 0;
      margin-right: 0;
    }
  }
  
  .main-sidebar-sticky {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
  
    .main-sidebar-body {
      height: calc(100vh - 122px);
      position: relative;
    }
  }
  
  @media (min-width: 992px) {
    .main-sidebar-sticky + .main-content {
      margin-left: 220px;
    }
  }
  
  @media (min-width: 1200px) {
    .main-sidebar-sticky + .main-content {
      margin-left: 240px;
    }
  }
  
  .main-sidebar-header {
    flex-shrink: 0;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0 20px;
    background: $white;
    border-bottom: 1px solid #dee4ec;
    position: fixed;
    z-index: 999;
    top: 0;
  }
  
  .main-sidebar-loggedin {
    padding: 20px 20px;
    display: flex;
    flex-shrink: 0;
    border-bottom: 1px solid #dee4ec;
    margin-top: 64px;
  }
  
  .main-sidebar .nav-pills-circle {
    position: relative;
    margin: 0 auto;
    text-align: center;
  
    &.nav .nav-item .dropdown-menu {
      left: -30px;
      min-width: 200px;
  
      .dropdown-item i {
        font-size: 14px;
        margin-right: 3px;
      }
    }
  
    .nav-pill {
      background: $white-1;
      border: 1px solid #eaedf1 !important;
      color: #68798b !important;
      border-radius: 5px;
      margin: 0 4px;
      padding: 0.4rem 0.7rem;
    }
  }
  
  .main-sidebar-hide .main-sidebar-loggedin .main-img-user {
    margin: auto;
  }
  
  .main-sidebar-loggedin {
    .main-img-user {
      width: 38px;
      height: 38px;
    }
  
    .media-body {
      margin-left: 12px;
      transition: left 0.4s ease, width 0.4s ease;
  
      h6 {
        font-weight: 700;
        font-size: 14px;
        color: $gray-900;
        margin-bottom: 2px;
      }
  
      span {
        display: block;
        color: $gray-600;
        font-size: 13px;
      }
    }
  }
  
  @media (min-width: 1200px) {
    .main-sidebar-body {
      padding: 0;
    }
  }
  
  .main-sidebar-hide .side-badge {
    display: none;
  }
  
  .main-sidebar-body {
    .nav {
      flex-direction: column;
    }
  
    .nav-item.active .nav-link .nav-badge {
      right: 12px !important;
    }
  
    .nav-sub {
      max-height: 0;
      overflow: hidden;
      -webkit-transition: max-height 0.9s ease;
      -o-transition: max-height 0.9s ease;
      transition: max-height 0.9s ease;
      transition: all 0.9s;
      margin-left: 32px;
    }
  
    .nav-item + .nav-item::before {
      content: '';
      position: absolute;
      top: 0;
      left: 30px;
      right: 0;
      border-top: 1px dotted #e2eaf9;
    }
  
    .nav-link {
      height: 45px;
      display: flex;
      align-items: center;
      padding: 0;
      font-weight: 400;
      font-size: 14px;
      color: $gray-900;
      padding-bottom: 2px;
    }
  }
  
  .main-sidebar-hide {
    .main-sidebar-body .nav-link .leftmenu-icon {
      margin: auto;
    }
  
    .nav-text-badge {
      display: none;
    }
  }
  
  .main-sidebar-body .nav-link i.typcn::before {
    width: auto;
  }
  
  .nav-link.with-sub {
    .angle {
      -webkit-transform-origin: center;
      -ms-transform-origin: center;
      transform-origin: center;
      position: absolute;
      top: 17px;
      right: 23px;
      font-size: 12px;
    }
  
    .side-badge {
      right: 23px;
      position: absolute;
    }
  }
  
  .sidebar-scroll {
    height: 100%;
    overflow-x: hidden;
  }
  
  .main-sidebar-body {
    .nav-link .leftmenu-icon .eve-icon {
      fill: $default-color;
    }
  
    .nav-sub {
      list-style: none;
      padding: 0;
      margin: 0 0 0 7px;
      position: relative;
    }
  
    .nav-sub-item {
      .nav-sub {
        padding: 0 0 0 18px;
        border-left-width: 1px;
        margin-left: 0;
  
        .nav-sub-link {
          height: auto;
          padding: 7px 0;
        }
      }
  
      &.active > .nav-sub-link {
        color: $primary;
        border-radius: 1px;
      }
  
      &.show .nav-sub {
        display: block;
      }
    }
  
    .nav-sub-link {
      height: 34px;
      display: flex;
      align-items: center;
      color: #6c7293;
      font-size: 13px;
      webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
  
      &.with-sub {
        justify-content: space-between;
  
        &::after {
          content: '\f3d0';
          font-family: 'Ionicons';
          font-size: 12px;
          font-weight: 400;
          display: inline-block;
          position: relative;
          margin-left: auto;
          color: $gray-300;
        }
      }
  
      + .nav-sub-link {
        border-top: 1px dotted $gray-300;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-sidebar-body .nav-sub-link.with-sub::after {
      margin-left: 4px;
      font-size: 12px;
    }
  }
  
  .main-sidebar-indigo-dark {
    .main-logo {
      color: $white;
      letter-spacing: -1.4px;
    }
  
    .main-sidebar-loggedin .media-body {
      h6 {
        color: $white;
        font-weight: 500;
      }
  
      span {
        color: $white-5;
      }
    }
  
    .main-img-user::after {
      box-shadow: none;
    }
  
    .main-sidebar-body {
      .nav-label {
        border-bottom-color: $white-2;
        color: rgba(255, 255, 255, 0.35);
        font-weight: 400;
      }
  
      .nav-item {
        + .nav-item {
          margin-top: -1px;
  
          &::before {
            border-top-color: rgba(255, 255, 255, 0.12);
          }
        }
  
        &.active {
          position: relative;
          z-index: 5;
  
          &::before {
            content: '';
            position: absolute;
            top: -1px;
            left: -20px;
            right: -20px;
            background-color: #33269c;
            height: 41px;
          }
  
          .nav-link {
            font-weight: 500;
            color: $white;
  
            &:hover, &:focus {
              font-weight: 500;
              color: $white;
            }
  
            i, &:hover i, &:focus i {
              color: $white;
            }
          }
        }
      }
  
      .nav-link {
        color: rgba(255, 255, 255, 0.45);
        font-weight: 400;
  
        i {
          color: rgba(255, 255, 255, 0.75);
        }
  
        &.with-sub::after {
          opacity: .3;
        }
  
        &:hover, &:focus {
          color: rgba(255, 255, 255, 0.75);
        }
      }
  
      .nav-sub {
        border-left-color: $white-1;
        padding-left: 0;
        padding-bottom: 0;
        position: relative;
  
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: -32px;
          right: -20px;
          bottom: 0;
          background-color: $black-1;
          z-index: 4;
        }
      }
  
      .nav-sub-item.active .nav-sub-link {
        color: $white;
      }
  
      .nav-sub-link {
        padding-left: 21px;
        color: $white-4;
        border-top-color: $white-1;
        position: relative;
        z-index: 5;
  
        &:hover, &:focus {
          color: $white;
        }
      }
    }
  }
  
  .main-sidebar-body .nav-sub .nav-sub-item .nav-sub-link:before::hover {
    content: "";
    position: absolute;
    left: 0px;
    display: block;
    height: 10%;
    width: 1px;
    background: $primary;
    left: -27px;
    z-index: 99;
    height: 34px;
  }
  
  .main-sidebar .search {
    position: relative;
    padding: 0 10px;
  
    .form-control {
      display: block;
      width: 214px;
      height: 40px;
      border-radius: 25px;
    }
  
    .btn {
      position: absolute;
      top: 0;
      right: 0;
      background-color: transparent;
      height: 40px;
      color: $gray-400;
      transition: none;
      font-size: 14px;
      padding-right: 31px;
    }
  }
  
  .text-nowrap {
    white-space: nowrap !important;
  }
  
  .main-sidebar {
    border-right: 1px solid #d8e0e7;
    box-shadow: $shadow;
  }
  
  /* ###### Sidebar  ###### */