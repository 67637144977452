/* ###### Chart  ###### */

.main-donut-chart {
    position: relative;
    border-radius: 50%;
    overflow: hidden;
  
    .slice {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  
    .chart-center {
      position: absolute;
      border-radius: 50%;
  
      span {
        display: block;
        text-align: center;
      }
    }
  }
  
  .morris-hover.morris-default-style {
    border-radius: 0;
  }
  
  /* ###### Chart  ###### */