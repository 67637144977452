/* ###### Navbar  ###### */
@media (max-width: 991.98px) {
    .main-navbar-show {
      overflow: hidden;
  
      .main-navbar-backdrop {
        visibility: visible;
        opacity: 1;
      }
  
      .main-navbar {
        transform: translateX(0);
        visibility: visible;
      }
    }
  }
  
  .main-navbar-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 900;
    visibility: hidden;
    opacity: 0;
    transition: all 0.4s;
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-navbar-backdrop {
      transition: none;
    }
  }
  
  .main-navbar {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 240px;
    overflow-y: auto;
    background-color: $white;
    z-index: 1000;
    visibility: hidden;
    transform: translateX(-240px);
    transition: all 0.4s;
  
    > {
      .container, .container-fluid {
        display: flex;
        flex-direction: column;
      }
    }
  
    .nav {
      flex-direction: column;
      padding: 20px;
      margin-bottom: 20px;
    }
  
    .nav-label {
      font-size: 10px;
      font-weight: 500;
      text-transform: uppercase;
      color: $gray-600;
      letter-spacing: 1px;
      display: block;
      margin-bottom: 10px;
    }
  
    .nav-item {
      position: relative;
  
      + .nav-item {
        border-top: 1px dotted $gray-200;
      }
  
      &.active .nav-link {
        color: $primary;
      }
  
      &.show > .nav-sub {
        display: block;
      }
    }
  
    .nav-item-mega {
      position: static;
    }
  
    .nav-link {
      padding: 0;
      color: $gray-900;
      font-size: 15px;
      font-weight: 500;
      position: relative;
      display: flex;
      align-items: center;
      height: 42px;
      outline: none;
  
      i {
        font-size: 26px;
        margin-right: 10px;
  
        &.typcn {
          line-height: 1;
          width: 20px;
  
          &::before {
            width: auto;
          }
        }
      }
  
      &.with-sub {
        &::after {
          content: '\f3d0';
          font-family: 'Ionicons';
          font-size: 11px;
          font-weight: 400;
          display: inline-block;
          position: relative;
          margin-left: auto;
          color: $gray-500;
        }
  
        &::before {
          content: '';
          position: absolute;
          top: 39px;
          left: 50%;
          margin-left: -7px;
          width: 15px;
          height: 15px;
          border: 2px solid transparent;
          border-top-color: rgba(28, 39, 60, 0.12);
          border-left-color: rgba(28, 39, 60, 0.12);
          transform: rotate(45deg);
          background-color: $white;
          z-index: 901;
          display: none;
        }
      }
    }
  
    .nav-sub {
      display: none;
      border-top: 1px dotted $gray-200;
      list-style: none;
      padding: 0;
      margin: 0;
    }
  
    .nav-sub-item {
      position: relative;
      display: block;
      margin-left: 30px;
  
      + .nav-sub-item {
        border-top: 1px dotted $gray-200;
      }
  
      > .nav-sub {
        left: 176px;
        top: -10px;
        display: none;
      }
  
      &.show > .nav-sub {
        display: block;
      }
  
      &.active > .nav-sub-link {
        color: $primary;
      }
    }
  
    .nav-sub-link {
      position: relative;
      font-size: .875rem;
      color: $gray-700;
      display: flex;
      align-items: center;
      height: 34px;
  
      &:hover, &:focus {
        color: $primary;
        outline: none;
      }
  
      &.with-sub {
        justify-content: space-between;
  
        &::after {
          content: '\f3d0';
          font-family: 'Ionicons';
          font-size: 11px;
          font-weight: 400;
          display: inline-block;
          position: relative;
          margin-left: auto;
          color: $gray-500;
        }
      }
    }
  
    .nav-sub-mega {
      top: 57px;
      left: 0;
      right: 0;
      background-color: transparent;
      padding: 7px 0;
      border: 0;
      width: auto;
      border-top: 1px dotted $gray-200;
      pointer-events: none;
  
      .container, .container-fluid {
        padding: 0;
        pointer-events: auto;
      }
  
      .container > div + div, .container-fluid > div + div {
        margin-top: 25px;
      }
  
      .nav {
        min-width: 120px;
        flex-direction: column;
        align-items: stretch;
        padding: 0;
        margin: 0;
  
        li:not(.nav-sub-item) {
          display: block;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 700;
          color: $gray-900;
          letter-spacing: .5px;
          margin-bottom: 10px;
          margin-top: 30px;
          margin-left: 30px;
  
          &:first-of-type {
            margin-top: 0;
          }
        }
      }
  
      .nav-sub-item + .nav-sub-item {
        padding-top: 7px;
        margin-top: 7px;
      }
  
      .nav-sub-link {
        height: auto;
      }
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .main-navbar {
      transition: none;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar {
      position: relative;
      top: auto;
      left: auto;
      bottom: auto;
      width: auto;
      padding: 0;
      border-right: 0;
      overflow-y: inherit;
      display: block;
      border-bottom: 1px solid rgba(28, 39, 60, 0.12);
      visibility: visible;
      transform: none;
      z-index: auto;
    }
  }
  
  @media (max-width: 991.98px) {
    .main-navbar > {
      .container, .container-fluid {
        padding: 0;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar > {
      .container, .container-fluid {
        flex-direction: row;
        align-items: center;
        height: 48px;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar > .container {
      padding: 0;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar > .container-fluid {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav {
      flex-direction: row;
      align-items: center;
      margin-bottom: 0;
      padding: 0;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-label {
      display: none;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-item + .nav-item {
      border-top: 0;
      margin-left: 15px;
      padding-left: 15px;
      border-left: 1px dotted $gray-200;
    }
  }
  
  @media (min-width: 1200px) {
    .main-navbar .nav-item + .nav-item {
      margin-left: 20px;
      padding-left: 20px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-item.show .nav-link::before {
      display: block;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-link {
      height: auto;
      font-size: 14px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-link i {
      margin-right: 8px;
      font-size: 22px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-link i.typcn {
      line-height: .9;
      width: auto;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-link.with-sub::after {
      margin-left: 4px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub {
      position: absolute;
      top: 44.5px;
      left: 0;
      width: 200px;
      background-color: $white;
      border: 2px solid rgba(28, 39, 60, 0.12);
      padding: 8px 20px;
      z-index: 900;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub-item {
      margin-left: 0;
    }
  }
  
  @media (max-width: 991.98px) {
    .main-navbar .nav-sub-item > .nav-sub > .nav-sub-item {
      margin-left: 37px;
  
      > .nav-sub-link {
        padding-left: 10px;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub-link {
      font-size: .8125rem;
      height: 36px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub-link.with-sub::after {
      margin-left: 4px;
      content: '\f3d1';
      font-size: 12px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub-mega {
      padding: 0 25px;
      border-top: 0;
    }
  }
  
  @media (max-width: 991.98px) {
    .main-navbar .nav-sub-mega {
      .container, .container-fluid {
        max-width: none;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub-mega {
      .container, .container-fluid {
        display: flex;
        background-color: $white;
        border: 2px solid rgba(28, 39, 60, 0.12);
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub-mega {
      .container > div, .container-fluid > div {
        display: flex;
        padding: 20px;
        flex: 1;
        margin-top: 0;
      }
    }
  }
  
  @media (min-width: 1200px) {
    .main-navbar .nav-sub-mega {
      .container > div, .container-fluid > div {
        padding: 20px 25px;
      }
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub-mega {
      .container > div + div, .container-fluid > div + div {
        margin-top: 0;
        border-left: 1px solid rgba(28, 39, 60, 0.05);
      }
    }
  }
  
  @media (min-width: 1200px) {
    .main-navbar .nav-sub-mega .nav {
      min-width: 140px;
    }
  }
  
  @media (max-width: 991.98px) {
    .main-navbar .nav-sub-mega .nav + .nav .nav-sub-item:first-child {
      margin-top: 6px;
      padding-top: 6px;
      border-top: 1px dotted $gray-200;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub-mega .nav + .nav {
      margin-left: 20px;
      margin-top: 31px;
      padding-top: 0;
      border-top: 0;
    }
  }
  
  @media (min-width: 1200px) {
    .main-navbar .nav-sub-mega .nav + .nav {
      margin-left: 25px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar .nav-sub-mega .nav li:not(.nav-sub-item) {
      margin-bottom: 15px;
      margin-left: 0;
    }
  }
  
  .main-navbar-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-bottom: 1px solid $gray-200;
    height: 60px;
  }
  
  @media (min-width: 992px) {
    .main-navbar-header {
      display: none;
    }
  }
  
  .main-navbar-search {
    position: relative;
    padding: 0 20px;
    border-bottom: 1px solid $gray-200;
  
    .form-control {
      border-width: 0;
      padding: 0;
  
      &:focus {
        box-shadow: none !important;
        border-color: $gray-300;
      }
    }
  
    .btn, .sp-container button {
      background-color: transparent;
      position: absolute;
      top: 0;
      right: 0;
      height: 38px;
      padding-left: 20px;
      padding-right: 20px;
      font-size: 16px;
      line-height: 1;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar-search {
      display: none;
    }
  }
  
  .sp-container .main-navbar-search button {
    background-color: transparent;
    position: absolute;
    top: 0;
    right: 0;
    height: 38px;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 16px;
    line-height: 1;
  }
  
  /* ###### 6.7 Navbar Variant  ###### */
  
  .main-navbar-two {
    .nav-item {
      &.active {
        position: relative;
  
        &::before {
          content: '';
          position: absolute;
        }
      }
  
      &.show .nav-link::before {
        display: none;
      }
    }
  
    .nav-link {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
    }
  
    .nav-sub {
      border-width: 1px;
      top: 35px;
      border-top-width: 0;
    }
  
    .nav-sub-item > .nav-sub {
      left: 178px;
      top: -8px;
      border-top-width: 1px;
    }
  
    .nav-sub-mega {
      top: 48px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar-two .nav-item.active::before {
      top: 33px;
      left: 0;
      right: 12px;
      bottom: auto;
      border-bottom: 2px solid $primary;
      z-index: 5;
    }
  }
  
  .main-navbar-three {
    .nav-item {
      display: block;
  
      + .nav-item {
        border-left-width: 0;
        padding-left: 0;
      }
  
      &.show .nav-link::before {
        display: none;
      }
  
      &.active .nav-link {
        color: $primary;
        font-weight: 700;
      }
    }
  
    .nav-link {
      text-transform: uppercase;
      font-size: 13px;
      font-weight: 700;
      color: $dark;
      position: relative;
  
      &:hover, &:focus {
        color: $gray-900;
      }
    }
  
    .nav-sub {
      top: 35px;
      border-width: 0;
      padding-bottom: 5px;
    }
  
    .nav-sub-item {
      + .nav-sub-item {
        border-top: 0;
      }
  
      .nav-sub {
        top: -8px;
        left: 182px;
      }
    }
  
    .nav-sub-link {
      height: 30px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar-three .nav-item + .nav-item {
      margin-left: 25px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar-three .nav-link {
      display: block;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar-three .nav-link i {
      display: none;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar-three .nav-sub {
      box-shadow: 0 0 0 2px rgba(180, 189, 206, 0.5);
      padding-bottom: 8px;
    }
  }
  
  @media (min-width: 992px) {
    .main-navbar-three .nav-sub-link {
      height: 36px;
    }
  }
  
  /* ###### Navbar  ###### */