/* ###### Perfect-scroll  ###### */

.ps {
    overflow: hidden;
  
    > .ps__rail-y {
      width: 2px;
      background-color: #bec9e3;
      z-index: 10;
      position: absolute;
      left: auto !important;
      right: 0;
      opacity: 0;
      transition: opacity 0.2s;
  
      > .ps__thumb-y {
        position: absolute;
        border-radius: 0;
        width: 2px;
        left: 1px;
        background-color: #d2d8e0;
      }
    }
  
    &.ps--active-y {
      &:hover > .ps__rail-y, &:focus > .ps__rail-y {
        opacity: 1;
      }
    }
  }
  
  @media (prefers-reduced-motion: reduce) {
    .ps > .ps__rail-y {
      transition: none;
    }
  }
  
  /* ###### Perfect-scroll  ###### */