/* ###### Main-profile  ###### */

.main-profile-menu {
    > .main-img-user {
      outline: none;
      width: 32px;
      height: 32px;
  
      &::before {
        content: '';
        position: absolute;
        bottom: -20px;
        right: -90px;
        margin-left: -7px;
        width: 14px;
        height: 14px;
        border: 2px solid transparent;
        border-top-color: $gray-300;
        border-left-color: $gray-300;
        transform: rotate(45deg);
        background-color: $white;
        z-index: 901;
        display: none;
      }
  
      &::after {
        display: none;
      }
    }
  
    .dropdown-menu {
      background-color: $white;
      width: 100%;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0;
      border-width: 0;
      margin-top: 0;
      z-index: 900;
      border-radius: 0;
      position: absolute;
      top: 56px;
      left: auto;
      right: -10px;
      bottom: auto;
      width: 230px;
      border-width: 1px;
    }
  
    .dropdown-item {
      position: relative;
      padding: 20px 10px;
      height: 38px;
      display: flex;
      align-items: center;
      font-size: 13px;
      color: $default-color;
  
      i {
        font-size: 16px;
        margin-right: 10px;
        width: 24px;
        text-align: center;
  
        &.typcn {
          line-height: .9;
        }
      }
  
      &:hover, &:focus {
        background-color: transparent;
        color: $primary;
      }
  
      + .dropdown-item {
        border-top: 1px dotted #e2eaf9;
      }
  
      &:last-child {
        margin-bottom: 10px;
      }
    }
  
    &.show .dropdown-menu {
      display: block;
    }
  }
  
  @media (min-width: 576px) {
    .main-profile-menu.show .main-img-user::before {
      display: block;
    }
  }
  
  .main-header-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .main-img-user {
      width: 100px;
      height: 100px;
      margin-bottom: 10px;
  
      &::after {
        display: none;
      }
    }
  
    h6 {
      font-size: 24px;
      font-weight: 700;
      color: $gray-900;
      margin-bottom: 0;
      font-size: 18px;
      color: $white;
    }
  
    span {
      display: block;
      font-size: 13px;
      margin-bottom: 0;
      color: $white;
    }
  }
  
  @media (min-width: 576px) {
    .main-header-profile .main-img-user {
      width: 80px;
      height: 80px;
    }
  }
  
  .main-header-sticky {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }
  
  /* ###### Main-profile  ###### */